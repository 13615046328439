// @flow

import { USERS_TABLE_UID, DEFAULT_TABLE_UID } from './shared/airtable-types';

import type { AirtableProjectWithSecrets } from './AirtableProjectWithSecrets';

export const createEmptyProject = (projectId: string): AirtableProjectWithSecrets => {
    const now = Date.now();
    const project: AirtableProjectWithSecrets = {
        id: projectId,
        name: 'Untitled',
        baseApiKey: '',
        userApiKey: '',
        pushNotificationsID: null,
        tables: {
            [DEFAULT_TABLE_UID]: {
                uid: DEFAULT_TABLE_UID,
                name: 'Untitled',
                airtableTableId: '',
                views: [],
                primaryField: '',
                subtitleField: '',
                creationTimeStamp: now,
                thumbnailField: null,
                layout: [],
                formId: '',
                userViewId: null,
                reportingField: null,
                searchField: null,
            },
            [USERS_TABLE_UID]: {
                uid: USERS_TABLE_UID,
                name: 'Users',
                airtableTableId: '',
                views: [],
                creationTimeStamp: now - 1,
                primaryField: '',
                subtitleField: '',
                thumbnailField: null,
                layout: [],
                formId: '',
                userViewId: '',
                reportingField: null,
                searchField: null,
            },
        },
        lastUpdatedTimeStamp: Date.now(),
    };

    return project;
};