// @flow

import type { UIElement } from "./ui-elements-types";
import { usersId, defaultTableId } from './ids';

export type TableLayout = $ReadOnlyArray<UIElement>;

export const USERS_TABLE_UID = usersId;
export const DEFAULT_TABLE_UID = defaultTableId;

export type ElementName = 'Button' | 'Text' | 'View' | 'Image' | 'Text Edit' | 'Checkbox' | 'Linked Records' | 'Audio' | 'Video';

export type AirtableTable = $ReadOnly<{|
    uid: string, // Probably the Firebase id, but maybe we don't want this. We can just use tableId
        name: string,
            creationTimeStamp: number, // Time stamp of when this table was created
                airtableTableId: string,
                    primaryField: string, // Field that's shown in the table view
                        subtitleField: string, // Field that's shown in the table view under the primary
                            thumbnailField: ?string, // Field for thumbnail that's shown in the table view next to the primary
                                reportingField: ?string, // Field for reporting any record. Should be a number type.
                                    searchField: ?string, // Field for search index.
                                        views: TableLayout,
                                            layout: TableLayout,
                                                formId: string,
                                                    userViewId: ?string, // This is only used for users 
|}>;

export type AirtableProject = $ReadOnly<{|
    id: string, // Probably the Firebase id
        name: string,
            tables: $ReadOnly < { [id: string]: AirtableTable } >,
                lastUpdatedTimeStamp: number, // Date.now()
                    pushNotificationsID: ?string,
|}>;
