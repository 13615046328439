// @flow

import React from 'react';

import { Dropdown, Input, Menu } from 'semantic-ui-react';

import './TextValueEditor.css';

import AirtableFieldPicker from './AirtableFieldPicker';

import { type ValueField, type ValueFieldType } from './shared/ui-elements-types';
import type { Fields } from './redux/reducers/airtableFields/types';

const getTitle = (type: ValueFieldType, staticTextLabel: ?string) => {
    switch (type) {
        case 'field':
            return 'Airtable Field';
        case 'static-text':
            if (staticTextLabel) {
                return staticTextLabel;
            } else {
                return 'Text';
            }
        default:
            /* eslint-disable no-unused-expressions */
            (type: empty);
            throw new Error(`Unknown value field type: ${type}`);
    }
};

type Props = {|
    textValue: ValueField,
        setTextValue: (ValueField) => void,
            isImage: boolean,
                staticPlaceholder: string,
                    currentTableAirtableFields: Fields,
                        staticTextLabel ?: string
                            |};

const TextValueEditor = (props: Props) => {
    const options = [{
        key: 1,
        id: 'field',
        text: getTitle('field', props.staticTextLabel),
        value: 1,
    }, {
        key: 2,
        id: 'static-text',
        text: getTitle('static-text', props.staticTextLabel),
        value: 2,
        textFieldPlaceHolder: props.staticPlaceholder, //'Enter text',
    }];

    const currentOption = options.find(x => x.id === props.textValue.type) || options[0];
    const currentOptionText = currentOption != null ? currentOption.text : '';

    return (
        <Menu>
            <Dropdown
                item
                style={{ backgroundColor: '#E4E4E4' }}
                // key={currentOptionText}
                text={currentOptionText}
                // value={currentOptionText}
                options={options}
                onChange={(e, { value }) => props.setTextValue({
                    type: (options.find(x => x.value === value) || options[0]).id,
                    value: '',
                })}
                selectOnBlur={false}
            />
            {currentOption.id === 'static-text' ? <Input
                labelPosition='left'
                className={'noBorderRadius'}
                placeholder={currentOption.textFieldPlaceHolder}
                value={props.textValue.value}
                onChange={(e, { value }) => props.setTextValue({
                    type: 'static-text',
                    value,
                })}
            /> : <AirtableFieldPicker
                    value={props.textValue.value}
                    setValue={value => props.setTextValue({
                        type: 'field',
                        value,
                    })}
                    currentTableAirtableFields={props.currentTableAirtableFields}
                    removeLeftBorderRadius={true}
                />}
        </Menu >
    );
};

export default TextValueEditor;
