/* eslint-disable jsx-a11y/anchor-is-valid */
// @flow

import React from "react";
import "./App.css";
// import Drift from 'react-driftjs';

import Main from "./Main";
// import { useAuth } from "./firebase";
import { ToastContainer } from "react-toastify";

const App = () => {
  // const auth = useAuth();
  // const userId = auth.user != null ? auth.user.uid : undefined;
  // const email = auth.user != null ? auth.user.email : undefined;
  return (
    <html>
      <head>
        {/* {userId != null && <Drift appId="mh8mmdvhixbb" userId={userId} attributes={{ userId, email }} />} */}
      </head>
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
        integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
        crossorigin="anonymous"
      />
      <link
        rel="stylesheet"
        href="//cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/semantic.min.css"
      />
      <link
        rel="stylesheet"
        href="https://unpkg.com/@progress/kendo-theme-material@latest/dist/all.css"
      />
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <Main />
      <ToastContainer
        onClick={() =>
          window.open("http://miniappmaker.com/download", "_blank").focus()
        }
      />
    </html>
  );
};

export default App;
