/* eslint-disable jsx-a11y/anchor-is-valid */
// @flow

import React, { useState, useEffect, useRef } from 'react';
import Iframe from 'react-iframe';
import ReactLoading from 'react-loading';
import { FiExternalLink } from "react-icons/fi";

import './HelpDrawer.css';

export type HelpType = 'share-your-app' | 'linked-records' | 'users-table-profile' | 'home-table' | 'design-elements' | 'users-table-settings' | 'views-settings' | 'table-settings' | 'project-settings' | 'overview';

type Props = $ReadOnly<{|
    type: HelpType
        |}>;

const border = `1px solid #E1E1E1`;

export const helpDrawerWidth = 400;

const HelpDrawer = (props: Props) => {
    const [isLoading, setIsLoading] = useState(true);
    const prevType = usePrevious(props.type);

    useEffect(() => {
        if (prevType !== props.type && !isLoading) {
            setIsLoading(true)
        }
    }, [isLoading, prevType, props.type]);

    const fullHeight = window.innerHeight;
    const helpHeaderHeight = 56;
    const webviewHeight = fullHeight - helpHeaderHeight;

    return (
        <div
            className={'help_sidebar'}
            style={{
                overflow: 'hidden',
                height: fullHeight,
                width: helpDrawerWidth,
                borderLeft: border,
                position: 'sticky',
                backgroundColor: 'white'
            }}
        >
            <div style={{
                width: '100%',
                height: helpHeaderHeight,
                paddingLeft: 16,
                display: 'flex',
                alignItems: 'center',
                borderBottom: border,
                backgroundColor: 'white',
                flexDirection: 'row',
            }}>
                <div>
                    <h3>Help</h3>
                </div>
                <div style={{ width: 12 }} />
                <a
                    href={'https://miniappmaker.com/help/'}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FiExternalLink style={{ color: '#357AF6' }} size={20} />
                </a>
            </div>
            <div
                style={{
                    width: '100%',
                    height: webviewHeight,
                    paddingTop: 8,
                    paddingLeft: 16,
                    overflow: 'hidden',
                    backgroundColor: 'white'
                }}
            >
                {isLoading && <div style={{
                    overflow: 'hidden', position: 'absolute', height: webviewHeight, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'
                }}>
                    <ReactLoading type={'spin'} color={'gray'} height={40} width={40} />
                </div>}

                <Iframe
                    className={isLoading ? 'hiddenIframe' : undefined}
                    url={`https://miniappmaker.com/help/reference/${props.type}/?inapp=true`}
                    width={'100%'}
                    height={webviewHeight}
                    frameBorder="0"
                    overflow={true}
                    onLoad={() => {
                        setIsLoading(false);
                    }}
                    scrolling={"yes"}
                    display="initial"
                    position="relative" />
            </div>
        </div >
    );
}

// Hook
function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();

    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

export default HelpDrawer;
