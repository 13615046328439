// @flow

import React, { useState, useCallback, useEffect } from 'react';
// import {render} from 'react-dom';
import { SortableContainer } from 'react-sortable-hoc';
import arrayMove from 'array-move';
// import { createSelectable } from 'react-selectable-fast'
import { MdAdd } from "react-icons/md";
import Popup from "reactjs-popup";
import { Dropdown, Form, Menu, Input, Card, Button, Icon } from 'semantic-ui-react';
import { ChromePicker } from 'react-color';
import TextValueEditor from './TextValueEditor';
import BlocksCard from './BlockCard';
import { connect } from 'react-redux';
import AuthRequiredHandler, { LEAVE_PAGE_ALERT } from './AuthRequiredHandler';
import { getIncompleteFieldsInTableSettings, getHasUnsavedChanges, getErrorsForTable, type TableError, getTableErrorMessage } from './redux/utils';
import { useBeforeunload } from 'react-beforeunload';
import { useSaveProject } from './useSaveProject';
import Badge from '@material-ui/core/Badge';
import { Alert } from 'reactstrap';
import { maybeGetAirtableError } from './airtable-utils';
import firebase from './firebase';
import AirtableFieldPicker from './AirtableFieldPicker';

import 'react-toastify/dist/ReactToastify.css';

import { useAirtableFields } from './redux/reducers/airtableFields/hooks';

import {
    allSizes,
    type UIElement,
    type ButtonElement,
    type TextElement,
    type TextElementFields,
    type ButtonElementFields,
    type ViewElementFields,
    type ViewElement,
    type ImageElementFields,
    type ImageElement,
    type TextEditElement,
    type TextEditElementFields,
    type LinkedRecordsElement,
    type LinkedRecordsElementFields,
    allKeyboardStyles,
    allTextEditTypes,
    type KeyboardStyle,
    type TextEditType,
    type CheckboxElementFields,
    type CheckboxElement,
    type AudioElement,
    type AudioElementFields,
    type VideoElement,
    type VideoElementFields,
} from './shared/ui-elements-types';

import {
    allActionTypes,
    titleForActionType,
    actionForTitle,
} from './shared/element-actions';
import { USERS_TABLE_UID, DEFAULT_TABLE_UID } from './shared/airtable-types';
import type { State, ProjectSaveState } from './redux/reducers/projects';
import type { AirtableTable, TableLayout, ElementName } from './shared/airtable-types';
import type {
    AddLayoutElement,
        UpdateTableLayout,
        UpdateTableViews,
        UpdateTableName,
        UpdateTableId,
        UpdateTableFormId,
        UpdateTableUserViewId,
        UpdateTablePrimaryField,
        UpdateTableSubtitleField,
        UpdateTableThumbnailField,
        UpdateTableReportingField,
        UpdateTableSearchField,
} from './redux/actions';
import type { AirtableProjectWithSecrets } from './AirtableProjectWithSecrets';
import { type HelpType } from './HelpDrawer';
import type { Fields } from './redux/reducers/airtableFields/types';

export const grayBorderColor = '#cfcfcf';


type Mode = 'layout' | 'settings' | 'views';

const TextElementForm = ({
    fields,
    setFields,
    currentTableAirtableFields,
}: {|
    fields: TextElementFields,
        setFields: (TextElementFields) => void ,
            currentTableAirtableFields: Fields,
                |}) => {
    const sizeOptions = allSizes.map((size, index) => ({ key: index, text: size, value: index }));

    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    // const [displayBackgroundColorPicker, setDisplayBackgroundColorPicker] = useState(false);

    // TODO undo is buggy. Choose an item in the dropdown, then undo, the selected label in the drop down is goofy

    return <Form.Field
        style={{
            // display: 'flex',
            // flexDirection: 'row',
        }}>
        <TextValueEditor
            textValue={fields.textValue}
            setTextValue={textValue => setFields({ ...fields, textValue })}
            isImage={false}
            staticPlaceholder={'Enter text'}
            currentTableAirtableFields={currentTableAirtableFields}
        />
        <Form.Group inline>
            <label style={{ marginTop: 16, }}>Size</label>
            <Menu style={{ marginTop: 16, }} compact>
                <Dropdown
                    selectOnBlur={false}
                    // style={{ width: 120 }}
                    text={fields.size}
                    options={sizeOptions}
                    onChange={(e, { value }) => setFields({
                        ...fields,
                        size: (sizeOptions.find(x => x.value === value) || sizeOptions[0]).text,
                    })}
                    fluid
                    item
                />
            </Menu>
            <div style={{ marginLeft: 16, marginTop: 16 }}>
                <Button.Group>
                    <Button
                        icon
                        active={fields.textAlign === 'left'}
                        onClick={() => setFields({
                            ...fields,
                            textAlign: 'left',
                        })}
                    >
                        <Icon name='align left' />
                    </Button>
                    <Button
                        icon
                        active={fields.textAlign === 'center'}
                        onClick={() => setFields({
                            ...fields,
                            textAlign: 'center',
                        })}
                    >
                        <Icon name='align center' />
                    </Button>
                    <Button
                        icon
                        active={fields.textAlign === 'right'}
                        onClick={() => setFields({
                            ...fields,
                            textAlign: 'right',
                        })}
                    >
                        <Icon name='align right' />
                    </Button>
                    <Button
                        icon
                        active={fields.textAlign === 'justify'}
                        onClick={() => setFields({
                            ...fields,
                            textAlign: 'justify',
                        })}
                    >
                        <Icon name='align justify' />
                    </Button>
                </Button.Group>
            </div>
            <div>
                <div
                    style={{
                        ...styles.colorSquare,
                        backgroundColor: fields.color,
                        marginTop: 16,
                        marginLeft: 16
                    }}
                    onClick={() => setDisplayColorPicker(true)}
                />
                {displayColorPicker ? <div style={{
                    position: 'absolute',
                    zIndex: '2',
                }}>
                    <div style={{
                        position: 'fixed',
                        top: '0px',
                        right: '0px',
                        bottom: '0px',
                        left: '0px',
                    }} onClick={() => setDisplayColorPicker(false)} />
                    <ChromePicker
                        color={fields.color}
                        onChange={(color) => setFields({
                            ...fields,
                            color: color.hex,
                        })}
                    />
                </div> : null}
            </div>

            {/* <label style={{ marginTop: 16, marginLeft: 32 }}>Background Color</label>
            <div>
                <div
                    style={{
                        ...styles.colorSquare,
                        backgroundColor: fields.backgroundColor,
                    }}
                    onClick={() => setDisplayBackgroundColorPicker(true)}
                />
                {displayBackgroundColorPicker ? <div style={{
                    position: 'absolute',
                    zIndex: '2',
                }}>
                    <div style={{
                        position: 'fixed',
                        top: '0px',
                        right: '0px',
                        bottom: '0px',
                        left: '0px',
                    }} onClick={() => setDisplayBackgroundColorPicker(false)} />
                    <ChromePicker
                        color={fields.backgroundColor}
                        onChange={(color) => setFields({
                            ...fields,
                            backgroundColor: color.hex,
                        })}
                    />
                </div> : null}
            </div> */}
        </Form.Group>

        {/* <Form.Group inline>
            <Form.Group grouped>
                <label style={{ marginTop: 16, marginBottom: 8, }}>Style</label>
                <Toolbar>
                    <ToolbarItem>
                        <DropDownButton class="k-icon k-i-arrow-60-down" text="Size 16" icon='k-icon k-i-arrow-60-down'>
                            <DropDownButtonItem text="Size 16" />
                            <DropDownButtonItem text="Size 18" />
                        </DropDownButton>
                    </ToolbarItem>
                    <ToolbarItem>
                        <ButtonGroup>
                            <Button icon="bold" title="Bold" togglable={true} />
                        </ButtonGroup>
                    </ToolbarItem>
                    <ToolbarItem>
                        <ButtonGroup>
                            <Button icon="align-left" title="Align Left" togglable={true} />
                            <Button icon="align-center" title="Align Center" togglable={true} />
                            <Button icon="align-right" title="Align Right" togglable={true} />
                        </ButtonGroup>
                    </ToolbarItem>
                </Toolbar>
            </Form.Group>
            <Form.Field
                style={{
                    width: 300,
                    marginLeft: 16,
                }}
            >
                <label>Font Size</label>
                <Input placeholder={'Enter font size'} />
            </Form.Field>
        </Form.Group> */}
    </Form.Field >
};

type ButtonElementFormProps = $ReadOnly<{|
    fields: $ReadOnly < ButtonElementFields >,
        setFields: (ButtonElementFields) => void,
            currentTableAirtableFields: Fields,
                usersTableAirtableFields: Fields,
                    |}>;

const ButtonElementForm = ({ fields, setFields, currentTableAirtableFields, usersTableAirtableFields }: ButtonElementFormProps) => {
    const actionOptions = allActionTypes.map((type, index) => ({ key: index, text: titleForActionType(type), value: index }));

    return <Form.Field
        style={{
            // display: 'flex',
            // flexDirection: 'row',
        }}>
        <label>Button Title</label>
        <TextValueEditor
            textValue={fields.textValue}
            setTextValue={textValue => setFields({ ...fields, textValue })}
            isImage={false}
            staticPlaceholder={'Enter text'}
            currentTableAirtableFields={currentTableAirtableFields}
        />

        <label style={{ marginTop: 16 }}>Action</label>
        <Menu style={{ marginTop: 6, }} compact>
            <Dropdown
                selectOnBlur={false}
                // style={{ width: 120 }}
                text={titleForActionType(fields.action.type)}
                options={actionOptions}
                onChange={(e, { value }) => {
                    setFields({
                        ...fields,
                        action: actionForTitle((actionOptions.find(x => x.value === value) || actionOptions[0]).text),
                    })
                }}
                fluid
                item
            />
        </Menu>
        {(fields.action.type === 'LINK_CURRENT_USER_RECORD' || fields.action.type === 'UNLINK_CURRENT_USER_RECORD') &&
            <Form.Group grouped>
                <label style={{ marginTop: 16 }}>Field in user's table</label>
                <AirtableFieldPicker
                    value={fields.action.fieldName}
                    setValue={value => setFields({
                        ...fields,
                        action: {
                            ...fields.action,
                            fieldName: value,
                        },
                    })}
                    currentTableAirtableFields={usersTableAirtableFields}
                />
            </Form.Group>
        }
        {fields.action.type === 'URL' &&
            <Form.Group grouped>
                <label style={{ marginTop: 16 }}>URL Value</label>
                <TextValueEditor
                    textValue={fields.action.url}
                    setTextValue={url => setFields({
                        ...fields,
                        action: {
                            ...fields.action,
                            url,
                        },
                    })}
                    isImage={false}
                    staticPlaceholder={'Enter url'}
                    currentTableAirtableFields={currentTableAirtableFields}
                    staticTextLabel={'URL'}
                />
            </Form.Group>
        }
    </Form.Field >
};

type ImageElementFormProps = $ReadOnly<{|
    fields: $ReadOnly < ImageElementFields >,
        setFields: (ImageElementFields) => void,
            currentTableAirtableFields: Fields,
                        |}>;

const ImageElementForm = ({ fields, setFields, currentTableAirtableFields }: ImageElementFormProps) => {
    return <Form.Field
        style={{
            // display: 'flex',
            // flexDirection: 'row',
        }}>
        <TextValueEditor
            textValue={fields.value}
            setTextValue={value => setFields({ ...fields, value })}
            isImage={true}
            staticTextLabel={'Image URL'}
            staticPlaceholder={'Enter image url'}
            currentTableAirtableFields={currentTableAirtableFields}
        />
        <Form.Group grouped>
            <label style={{ marginTop: 16 }}>Height</label>
            <Input
                placeholder={'Enter height'}
                value={fields.height}
                onChange={(e, { value }) => {
                    // TODO we should only be allowing numbers
                    setFields({
                        ...fields,
                        height: value,
                    });
                }}
            />
        </Form.Group>
    </Form.Field >
};

type ViewElementFormProps = $ReadOnly<{|
    fields: $ReadOnly < ViewElementFields >,
        setFields: (ViewElementFields) => void,
                            |}>;

const ViewElementForm = ({ fields, setFields }: ViewElementFormProps) => {
    return <Form.Field
        style={{
            // display: 'flex',
            // flexDirection: 'row',
        }}>
        <Form.Group grouped>
            <label style={{ marginTop: 0 }}>View Name</label>
            <Input
                placeholder={'Enter view name'}
                value={fields.name}
                onChange={(e, { value }) => {
                    setFields({
                        ...fields,
                        name: value,
                    });
                }}
            />
        </Form.Group>
        <Form.Group grouped>
            <label style={{ marginTop: 16 }}>View ID</label>
            <Form.Input
                placeholder={'Enter view ID'}
                value={fields.id}
                error={maybeGetAirtableError('viewId', fields.id, 'Invalid. View ID is 17 characters long and starts with "viw".')}
                onChange={(e, { value }) => {
                    setFields({
                        ...fields,
                        id: value,
                    });
                }}
            />
        </Form.Group>
    </Form.Field >
};

type TextEditElementFormProps = $ReadOnly<{|
    fields: TextEditElementFields,
        setFields: (TextEditElementFields) => void,
            currentTableAirtableFields: Fields,
                            |}>;

const TextEditElementForm = ({ fields, setFields, currentTableAirtableFields }: TextEditElementFormProps) => {

    const getTitleFromKeyboardStyle = (keyboardStyle: KeyboardStyle) => {
        switch (keyboardStyle) {
            case 'default':
                return 'Default';
            case 'numeric':
                return 'Numeric';
            case 'email-address':
                return 'Email address';
            case 'phone-pad':
                return 'Phone number';
            default:
                /* eslint-disable no-unused-expressions */
                (keyboardStyle: empty);
                throw new Error(`Unknown keyboardStyle: ${keyboardStyle}`);
        }
    }

    const styleOptions = allKeyboardStyles.map((style, index) => ({
        key: index,
        text: getTitleFromKeyboardStyle(style),
        value: index
    }));

    const typeOptions = allTextEditTypes.map((type, index) => ({
        key: index,
        text: type,
        value: index
    }));

    const selectedType: TextEditType = fields.type != null ? fields.type : 'Text';

    return <Form.Field
        style={{
            // display: 'flex',
            // flexDirection: 'row',
        }}>
        <Form.Group grouped>
            <label style={{ marginTop: 0 }}>Field Name</label>
            <AirtableFieldPicker
                value={fields.fieldName}
                setValue={value => setFields({
                    ...fields,
                    fieldName: value,
                })}
                currentTableAirtableFields={currentTableAirtableFields}
            />
        </Form.Group>
        <Form.Group grouped>
            <label style={{ marginTop: 12 }}>Placeholder</label>
            <Input
                placeholder={'Enter placeholder'}
                value={fields.placeholder}
                onChange={(e, { value }) => {
                    setFields({
                        ...fields,
                        placeholder: value,
                    });
                }}
            />
        </Form.Group>
        <Form.Group grouped>
            <label style={{ marginTop: 12 }}>Type</label>
            <div>
                <Menu style={{}} compact>
                    <Dropdown
                        selectOnBlur={false}
                        text={selectedType}
                        options={typeOptions}
                        onChange={(e, { value }) => {
                            setFields({
                                ...fields,
                                type: allTextEditTypes[
                                    value
                                ],
                            });
                        }}
                        fluid
                        item
                    />
                </Menu>
            </div>
        </Form.Group>
        <Form.Group grouped>
            <label style={{ marginTop: 12 }}>Keyboard style</label>
            <div>
                <Menu style={{}} compact>
                    <Dropdown
                        selectOnBlur={false}
                        text={getTitleFromKeyboardStyle(fields.keyboardStyle != null ? fields.keyboardStyle : 'default')}
                        options={styleOptions}
                        onChange={(e, { value }) => {
                            setFields({
                                ...fields,
                                keyboardStyle: allKeyboardStyles[
                                    value
                                ],
                            });
                        }}
                        fluid
                        item
                    />
                </Menu>
            </div>
        </Form.Group>
        {/* <Form.Group>
            <label style={{ marginTop: 4, }}> </label>
            <Menu style={{ marginTop: 4, }} compact>
                <Dropdown
                    selectOnBlur={false}
                    text={getTitleFromKeyboardStyle(fields.keyboardStyle != null ? fields.keyboardStyle : 'default')}
                    options={styleOptions}
                    onChange={(e, { value }) => {
                        setFields({
                            ...fields,
                            keyboardStyle: allKeyboardStyles[
                                value
                            ],
                        });
                    }}
                    fluid
                    item
                />
            </Menu>
        </Form.Group> */}
    </Form.Field >
};

type LinkedRecordsElementFormProps = $ReadOnly<{|
    tables: $ReadOnlyArray < $ReadOnly < {|
        uid: string,
            name: string,
        |}>>,
    fields: LinkedRecordsElementFields,
        setFields: (LinkedRecordsElementFields) => void,
            currentTableAirtableFields: Fields,
                                |}>;

const LinkedRecordsElementForm = ({ tables, fields, setFields, currentTableAirtableFields }: LinkedRecordsElementFormProps) => {
    const getTitleFromTableUID = (uid: ?string) => {
        const noneTitle = 'Choose table';
        if (uid == null) {
            return noneTitle;
        } else {
            const table = tables.find(table => table.uid === uid);
            if (table) {
                return table.name;
            } else {
                return noneTitle;
            }
        }
    }

    const styleOptions = tables.map((table, index) => ({
        key: index,
        text: getTitleFromTableUID(table.uid),
        value: index
    }));

    return <Form.Field
        style={{
            // display: 'flex',
            // flexDirection: 'row',
        }}>
        <Form.Group grouped>
            <label style={{ marginTop: 0 }}>Linked Table</label>
            <div>
                <Menu style={{}} compact>
                    <Dropdown
                        selectOnBlur={false}
                        text={getTitleFromTableUID(fields.tableUID)}
                        options={styleOptions}
                        onChange={(e, { value }) => {
                            setFields({
                                ...fields,
                                tableUID: tables[
                                    value
                                ].uid,
                            });
                        }}
                        fluid
                        item
                    />
                </Menu>
            </div>
        </Form.Group>
        <Form.Group grouped>
            <label style={{ marginTop: 0 }}>Field Name</label>
            <AirtableFieldPicker
                value={fields.fieldName}
                setValue={value => setFields({
                    ...fields,
                    fieldName: value,
                })}
                currentTableAirtableFields={currentTableAirtableFields}
            />
        </Form.Group>
        <Form.Group grouped>
            <label style={{ marginTop: 0 }}>Primary field (lookup field of linked records)</label>
            <AirtableFieldPicker
                value={fields.primaryLookupField}
                setValue={value => setFields({
                    ...fields,
                    primaryLookupField: value,
                })}
                currentTableAirtableFields={currentTableAirtableFields}
            />
        </Form.Group>
        <Form.Group grouped>
            <label style={{ marginTop: 0 }}>Subtitle field (lookup field of linked records, optional)</label>
            <AirtableFieldPicker
                value={fields.subtitleLookupField}
                setValue={value => setFields({
                    ...fields,
                    subtitleLookupField: value,
                })}
                currentTableAirtableFields={currentTableAirtableFields}
            />
        </Form.Group>
        <Form.Group grouped>
            <label style={{ marginTop: 0 }}>Image field (lookup field of linked records, optional)</label>
            <AirtableFieldPicker
                value={fields.imageLookupField}
                setValue={value => setFields({
                    ...fields,
                    imageLookupField: value,
                })}
                currentTableAirtableFields={currentTableAirtableFields}
            />
        </Form.Group>
    </Form.Field >
};

type CheckboxFormProps = $ReadOnly<{|
    fields: $ReadOnly < CheckboxElementFields >,
        setFields: (CheckboxElementFields) => void,
            currentTableAirtableFields: Fields,
                            |}>;

const CheckboxElementForm = ({ fields, setFields, currentTableAirtableFields }: CheckboxFormProps) => {
    return <Form.Field
        style={{
            // display: 'flex',
            // flexDirection: 'row',
        }}>
        <Form.Group grouped>
            <label style={{ marginTop: 0 }}>Title</label>
            <Input
                placeholder={'Enter title'}
                value={fields.title}
                onChange={(e, { value }) => {
                    setFields({
                        ...fields,
                        title: value,
                    });
                }}
            />
        </Form.Group>
        <Form.Group grouped>
            <label style={{ marginTop: 16 }}>Field Name</label>
            <AirtableFieldPicker
                value={fields.valueFieldName}
                setValue={value => setFields({
                    ...fields,
                    valueFieldName: value,
                })}
                currentTableAirtableFields={currentTableAirtableFields}
            />
        </Form.Group>
    </Form.Field >
};

type AudioFormProps = $ReadOnly<{|
    fields: $ReadOnly < AudioElementFields >,
        setFields: (AudioElementFields) => void,
            currentTableAirtableFields: Fields,
                            |}>;

const AudioElementForm = ({ fields, setFields, currentTableAirtableFields }: AudioFormProps) => {
    return <Form.Field
        style={{
            // display: 'flex',
            // flexDirection: 'row',
        }}>
        <label>Audio Clip URL</label>
        <TextValueEditor
            textValue={fields.value}
            setTextValue={value => setFields({ ...fields, value })}
            isImage={false}
            staticTextLabel={'Audio URL'}
            staticPlaceholder={'Enter audio clip URL'}
            currentTableAirtableFields={currentTableAirtableFields}
        />
    </Form.Field >
};

type VideoFormProps = $ReadOnly<{|
    fields: $ReadOnly < VideoElementFields >,
        setFields: (VideoElementFields) => void,
            currentTableAirtableFields: Fields,
                            |}>;

const VideoElementForm = ({ fields, setFields, currentTableAirtableFields }: VideoFormProps) => {
    return <Form.Field
        style={{
            // display: 'flex',
            // flexDirection: 'row',
        }}>
        <label>Video Clip URL</label>
        <TextValueEditor
            textValue={fields.value}
            setTextValue={value => setFields({ ...fields, value })}
            isImage={false}
            staticTextLabel={'Video URL'}
            staticPlaceholder={'Enter video clip URL'}
            currentTableAirtableFields={currentTableAirtableFields}
        />
    </Form.Field >
};

type SortableListProps = {
    project: $ReadOnly<AirtableProjectWithSecrets>,
    tableId: string,
    mode: Mode,
    elements: Array<UIElement>,
    setElements: (Array<UIElement>) => void,
    addRow: (elementName: ElementName) => void,
    goBackToProject: () => void,
    tableErrors: $ReadOnlyArray<TableError>,
    setHelpType?: (type: HelpType) => void,
    currentTableAirtableFields: Fields,
    usersTableAirtableFields: Fields,
}

const SortableList = SortableContainer(({ usersTableAirtableFields, currentTableAirtableFields, setHelpType, tableErrors, project, tableId, elements, setElements, addRow, mode, goBackToProject }: SortableListProps) => {
    const isADeletableTable = tableId !== USERS_TABLE_UID && tableId !== DEFAULT_TABLE_UID;
    const canDeleteTable = isADeletableTable && getErrorsForTable(project, project.tables[tableId]).indexOf('non-default-table-not-linked') !== -1;

    return (
        <ul
            style={{
                flexDirection: 'column',
                display: 'table',
                // border: `1px solid ${grayBorderColor}`,
                // margin: 0,
                maxWidth: 480,
                width: '100%',
                padding: 16,
                minHeight: mode === 'layout' || mode === 'views' ? 600 : 0,
            }}
            onMouseDown={() => mode === 'layout' && setHelpType && setHelpType('design-elements')}
            onClick={(e) => e.stopPropagation()}
        >
            {mode === 'layout' && tableErrors.length > 0 && tableErrors.map(error =>
                <Alert style={styles.alert} color="danger">
                    <div style={styles.alertLeftContainer}>
                        <div style={styles.alertLeftText}>{'⚠️'}</div>
                    </div>
                    <p>{getTableErrorMessage(error)}</p>
                </Alert>
            )}
            {elements.map((element, index) => (
                // <Resizable
                //     size={item.height != null ? { height: item.height } : undefined}
                //     minHeight={32}
                //     enable={{ top: false, right: false, bottom: true, left: false, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }}
                //     onResizeStop={(e, direction, ref, d) => {
                //         const height = item.height + d.height;
                //         const newItems = items.slice();
                //         newItems[index] = {
                //             ...items[index],
                //             height,
                //         };

                //         setItems(newItems);
                //     }}
                // >
                // <div
                //     style={{
                //         margin: 0,
                //         padding: 0,
                //         // height: '100%',
                //     }}
                //     onClick={() => {
                //         const newItems = items.slice().map(item => {
                //             if (item.isSelected) {
                //                 return {
                //                     ...item,
                //                     isSelected: false,
                //                 }
                //             } else {
                //                 return item;
                //             }
                //         });
                //         newItems[index] = {
                //             ...items[index],
                //             isSelected: !item.isSelected,
                //         };

                //         setItems(newItems);
                //     }}
                // >
                <BlocksCard
                    key={`element-${index}`}
                    title={element.elementName}
                    deleteElement={() => {
                        const newValue = elements.filter((element, i) => index !== i);
                        if (mode === 'settings') {
                            alert('Error: Unable to delete elements in table settings.');
                        } else {
                            setElements(newValue);
                        }
                    }}
                    moveItemUp={() => {
                        if (index > 0) {
                            const copyOfElements = [...elements];
                            const itemToSwap = copyOfElements[index - 1];
                            copyOfElements[index - 1] = copyOfElements[index];
                            copyOfElements[index] = itemToSwap;
                            setElements(copyOfElements);
                        }
                    }}
                    moveItemDown={() => {
                        if (index < elements.length - 1) {
                            const copyOfElements = [...elements];
                            const itemToSwap = copyOfElements[index + 1];
                            copyOfElements[index + 1] = copyOfElements[index];
                            copyOfElements[index] = itemToSwap;
                            setElements(copyOfElements);
                        }
                    }}
                >
                    {
                        element.elementName === 'Text' && <TextElementForm
                            fields={element.fields}
                            currentTableAirtableFields={currentTableAirtableFields}
                            setFields={fields => {
                                const newElement: TextElement = {
                                    ...element,
                                    fields,
                                };
                                const newElements = elements.slice();
                                newElements[index] = newElement;
                                setElements(newElements);
                            }}
                        />
                    }
                    {
                        element.elementName === 'Button' && <ButtonElementForm
                            fields={element.fields}
                            currentTableAirtableFields={currentTableAirtableFields}
                            usersTableAirtableFields={usersTableAirtableFields}
                            setFields={fields => {
                                const newElement: ButtonElement = {
                                    ...element,
                                    fields,
                                };
                                const newElements = elements.slice();
                                newElements[index] = newElement;
                                setElements(newElements);
                            }}
                        />
                    }
                    {
                        element.elementName === 'Image' && <ImageElementForm
                            fields={element.fields}
                            currentTableAirtableFields={currentTableAirtableFields}
                            setFields={fields => {
                                const newElement: ImageElement = {
                                    ...element,
                                    fields,
                                };
                                const newElements = elements.slice();
                                newElements[index] = newElement;
                                setElements(newElements);
                            }}
                        />
                    }
                    {
                        element.elementName === 'View' && <ViewElementForm
                            fields={element.fields}
                            setFields={fields => {
                                const newElement: ViewElement = {
                                    ...element,
                                    fields,
                                };
                                const newElements = elements.slice();
                                newElements[index] = newElement;
                                setElements(newElements);
                            }}
                        />
                    }
                    {
                        element.elementName === 'Text Edit' && <TextEditElementForm
                            fields={element.fields}
                            currentTableAirtableFields={currentTableAirtableFields}
                            setFields={fields => {
                                const newElement: TextEditElement = {
                                    ...element,
                                    fields,
                                };
                                const newElements = elements.slice();
                                newElements[index] = newElement;
                                setElements(newElements);
                            }}
                        />
                    }
                    {
                        element.elementName === 'Linked Records' && <LinkedRecordsElementForm
                            fields={element.fields}
                            currentTableAirtableFields={currentTableAirtableFields}
                            setFields={fields => {
                                const newElement: LinkedRecordsElement = {
                                    ...element,
                                    fields,
                                };
                                const newElements = elements.slice();
                                newElements[index] = newElement;
                                setElements(newElements);
                            }}
                            tables={Object.keys(project.tables).map(key => ({
                                uid: project.tables[key].uid,
                                name: project.tables[key].name,
                            }))}
                        />
                    }
                    {
                        element.elementName === 'Checkbox' && <CheckboxElementForm
                            fields={element.fields}
                            currentTableAirtableFields={currentTableAirtableFields}
                            setFields={fields => {
                                const newElement: CheckboxElement = {
                                    ...element,
                                    fields,
                                };
                                const newElements = elements.slice();
                                newElements[index] = newElement;
                                setElements(newElements);
                            }}
                        />
                    }
                    {
                        element.elementName === 'Video' && <VideoElementForm
                            fields={element.fields}
                            currentTableAirtableFields={currentTableAirtableFields}
                            setFields={fields => {
                                const newElement: VideoElement = {
                                    ...element,
                                    fields,
                                };
                                const newElements = elements.slice();
                                newElements[index] = newElement;
                                setElements(newElements);
                            }}
                        />
                    }
                    {
                        element.elementName === 'Audio' && <AudioElementForm
                            fields={element.fields}
                            currentTableAirtableFields={currentTableAirtableFields}
                            setFields={fields => {
                                const newElement: AudioElement = {
                                    ...element,
                                    fields,
                                };
                                const newElements = elements.slice();
                                newElements[index] = newElement;
                                setElements(newElements);
                            }}
                        />
                    }
                </BlocksCard>
                // </div>
                // </Resizable>
            ))
            }

            {mode === 'settings' && isADeletableTable && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}> <Button
                negative={canDeleteTable}
                onClick={async () => {
                    if (!canDeleteTable) {
                        alert('To delete this table, remove it from any Linked Records that link to it.');
                        return;
                    }

                    if (window.confirm('Are you sure you want to delete this table? \n🚨YOU CANNOT UNDO THIS! 🚨')) {
                        try {
                            await firebase.firestore().collection('projects').doc(project.id).update({
                                [`data.tables.${tableId}`]: firebase.firestore.FieldValue.delete(),
                            });

                            goBackToProject();
                        } catch {
                            alert('Error: Could not delete table');
                        }
                    }
                }}
            >
                Delete Table
            </Button>
            </div>

            }

            {(mode === 'layout' || mode === 'views') && < AddRowButton
                mode={mode}
                tableHasNoRow={elements.length === 0}
                addTextRow={() => addRow('Text')}
                addButtonRow={() => addRow('Button')}
                addViewRow={() => addRow('View')}
                addImageRow={() => addRow('Image')}
                addTextEditRow={() => addRow('Text Edit')}
                addCheckboxRow={() => addRow('Checkbox')}
                addLinkedRecordsRow={() => addRow('Linked Records')}
                addAudio={() => addRow('Audio')}
                addVideo={() => addRow('Video')}
            />}
            <div style={{ height: 50 }} />
        </ul >
    );
});

const darkColor = '#2F414E';
const darkHoverColor = '#43545f';

type PopUpItemProps = {|
    title: string,
        onClick: () => void,
            setIsLastItemHovering ?: (boolean) => void,
                                                                |}
const PopUpItem = (props: PopUpItemProps) => {
    const [isHovering, setIsHovering] = useState(false);

    return (
        <li
            style={{
                backgroundColor: isHovering ? darkHoverColor : darkColor,
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                padding: 0,
                margin: 0,
                height: 48
            }}
            onMouseEnter={() => {
                setIsHovering(true);
                props.setIsLastItemHovering && props.setIsLastItemHovering(true);
            }}
            onMouseLeave={() => {
                setIsHovering(false);
                props.setIsLastItemHovering && props.setIsLastItemHovering(false);
            }}
            onClick={props.onClick}
        >
            <p>{props.title}</p>
        </li>
    );
}

type AddRowButtonProps = {|
    mode: Mode,
        tableHasNoRow: boolean,
            addTextRow: () => void,
                addButtonRow: () => void,
                    addViewRow: () => void,
                        addImageRow: () => void,
                            addTextEditRow: () => void,
                                addCheckboxRow: () => void,
                                    addLinkedRecordsRow: () => void,
                                        addAudio: () => void,
                                            addVideo: () => void,
                                                                |};

const AddRowButton = (props: AddRowButtonProps) => {
    const [isPopUpHidden, setIsPopUpHidden] = useState(false);
    const [isLeftItemHovering, setIsLastItemHovering] = useState(false);

    useEffect(
        () => {
            if (isPopUpHidden) {
                setIsPopUpHidden(false);
            }
        },
        [isPopUpHidden, setIsPopUpHidden]
    )

    if (props.mode === 'views') {
        return <div
            style={{
                height: 48,
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
            }}
            onClick={props.addViewRow}
        >
            <MdAdd size={60} />
        </div>;
    } else if (props.mode === 'layout') {
        return (
            <Popup
                trigger={
                    <div
                        style={{
                            height: 48,
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                    >
                        <MdAdd size={60} />
                    </div>
                }
                position={props.tableHasNoRow ? "bottom center" : "top center"}
                on="hover"
                contentStyle={{ padding: 0 }}
                arrowStyle={{ backgroundColor: isLeftItemHovering ? darkHoverColor : darkColor }}
                disabled={isPopUpHidden ? true : undefined}
            >
                {props.mode === 'layout' && <PopUpItem
                    title={'Text'}
                    onClick={() => {
                        props.addTextRow();
                        setIsPopUpHidden(true);
                    }}
                    setIsLastItemHovering={props.tableHasNoRow ? setIsLastItemHovering : undefined}
                />}
                {props.mode === 'layout' && <PopUpItem
                    title={'Button'}
                    onClick={() => {
                        props.addButtonRow();
                        setIsPopUpHidden(true);
                    }}
                />}
                {props.mode === 'layout' && <PopUpItem
                    title={'Image'}
                    onClick={() => {
                        props.addImageRow();
                        setIsPopUpHidden(true);
                    }}
                />}
                {props.mode === 'layout' && <PopUpItem
                    title={'Text Edit'}
                    onClick={() => {
                        props.addTextEditRow();
                        setIsPopUpHidden(true);
                    }}
                />}
                {props.mode === 'layout' && <PopUpItem
                    title={'Checkbox'}
                    onClick={() => {
                        props.addCheckboxRow();
                        setIsPopUpHidden(true);
                    }}
                />}
                {props.mode === 'layout' && <PopUpItem
                    title={'Audio'}
                    onClick={() => {
                        props.addAudio();
                        setIsPopUpHidden(true);
                    }}
                />}
                {props.mode === 'layout' && <PopUpItem
                    title={'Video'}
                    onClick={() => {
                        props.addVideo();
                        setIsPopUpHidden(true);
                    }}
                />}
                {props.mode === 'layout' && <PopUpItem
                    title={'Linked Records'}
                    onClick={() => {
                        props.addLinkedRecordsRow();
                        setIsPopUpHidden(true);
                    }}
                    setIsLastItemHovering={props.tableHasNoRow ? undefined : setIsLastItemHovering}
                />}
                {props.mode === 'views' && <PopUpItem
                    title={'View'}
                    onClick={() => {
                        props.addViewRow();
                        setIsPopUpHidden(true);
                    }}
                    setIsLastItemHovering={setIsLastItemHovering}
                />}
            </Popup >
        );
    } else {
        throw new Error('Add button should not be shown in this state.');
    }
}

const MenuButton = ({ disabled, text, onClick }: { disabled?: boolean, text: string, onClick: () => void }) => {
    return <div
        style={{
            backgroundColor: !disabled ? '#E8E8E8' : undefined,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 30,
            paddingLeft: 6,
            paddingRight: 6,
            borderRadius: 4,
            cursor: !disabled ? 'pointer' : undefined,
            color: !disabled ? 'black' : '#D3D3D3',
        }}
        onClick={!disabled ? onClick : undefined}
    >
        {text}
    </div>
};

const UIEditor = (props: $ReadOnly<Props>) => {
    const mode: Mode = props.mode;
    let elements: TableLayout;
    let setElements: (TableLayout) => void;

    props.history.block((location, action) => {
        return action === 'POP' && props.hasUnsavedChanges ? LEAVE_PAGE_ALERT : null;
    });
    useBeforeunload(event => props.hasUnsavedChanges ? LEAVE_PAGE_ALERT : null);

    switch (mode) {
        case 'layout':
            elements = props.table != null ? props.table.layout : [];
            setElements = props.setLayout;
            break;
        case 'settings':
            elements = [];
            setElements = () => alert('Error: Unable to set elements in table settings.');
            break;
        case 'views':
            elements = props.table != null ? props.table.views : [];
            setElements = props.setViews;
            break;
        default:
            /* eslint-disable no-unused-expressions */
            (mode: empty);
            throw new Error(`Unknown mode type: ${mode}`);
    }

    const incompleteFields: Array<string> = props.table != null ? getIncompleteFieldsInTableSettings(props.table) : [];
    const airtableTableIdError = incompleteFields.indexOf('airtableTableId') === -1 ? undefined : true;
    const primaryFieldError = incompleteFields.indexOf('primaryField') === -1 ? undefined : true;

    const onSortEnd = useCallback(
        ({ oldIndex, newIndex }) => {
            setElements(arrayMove(elements, oldIndex, newIndex));
        },
        [setElements, elements],
    );

    const {
        saveProjectToFirebase,
        isProjectSaving,
    } = useSaveProject(props.match.params.projectId);

    const currentTableAirtableFields = useAirtableFields(
        props.project != null ? props.project.userApiKey : null,
        props.project != null ? props.project.baseApiKey : null,
        props.table != null ? props.table.airtableTableId : null,
    );

    const usersTableAirtableFields = useAirtableFields(
        props.project != null ? props.project.userApiKey : null,
        props.project != null ? props.project.baseApiKey : null,
        props.project != null ? props.project.tables[USERS_TABLE_UID].airtableTableId : null,
    );

    const tableErrors: $ReadOnlyArray<TableError> = props.project != null && props.table != null ? getErrorsForTable(props.project, props.table) : [];

    const airtableTableId = props.table != null ? props.table.airtableTableId : '';
    const tableUID = props.table != null ? props.table.uid : null;
    const primaryField = props.table != null ? props.table.primaryField : null;
    const subtitleField = props.table != null ? props.table.subtitleField : null;
    const thumbnailField = props.table != null ? props.table.thumbnailField : null;
    const formId = props.table != null ? props.table.formId : null;
    const userViewId = props.table != null ? props.table.userViewId : null;
    const reportingField = props.table != null ? props.table.reportingField : null;
    const searchField = props.table != null ? props.table.searchField : null;

    const goBackToProject = (reload: boolean = false) => {
        props.history.push(`/projects/${props.match.params.projectId}`);
        reload && window.location.reload();
    };

    return (
        <AuthRequiredHandler
            block={props.history.block}
            hasUnsavedChanges={props.hasUnsavedChanges}
            forceLoading={isProjectSaving}
        >
            {props.project && props.table && <div
                style={{
                    display: 'flex',
                    flex: 1,
                    width: '100%'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                        // justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <div style={{ maxWidth: 480, width: '100%' }}>
                        {props.topBarIsHidden !== true && mode === 'layout' && <div style={{ paddingLeft: 16, paddingRight: 16, display: 'flex', alignItems: 'center', flexDirection: 'row', height: 48, borderBottom: '1px solid #cccccc', }}>
                            {props.table.uid === DEFAULT_TABLE_UID &&
                                <MenuButton text={'Views'} onClick={() => {
                                    if (props.hasUnsavedChanges) {
                                        if (window.confirm(LEAVE_PAGE_ALERT)) {
                                            props.showViewsModal && props.showViewsModal();
                                        }
                                    } else {
                                        props.showViewsModal && props.showViewsModal();
                                    }
                                }} />
                            }
                            {props.table.uid === DEFAULT_TABLE_UID && <div style={{ width: 16 }} />}
                            <Badge color="secondary" variant='dot' invisible={!(airtableTableIdError || primaryFieldError)}>
                                <MenuButton text={'Table Settings'} onClick={() => {
                                    if (props.hasUnsavedChanges) {
                                        if (window.confirm(LEAVE_PAGE_ALERT)) {
                                            props.showTableSettingsModal && props.showTableSettingsModal();
                                        }
                                    } else {
                                        props.showTableSettingsModal && props.showTableSettingsModal();
                                    }
                                }} />
                            </Badge>
                            <div style={{ display: 'flex', flex: 1 }} />
                            <MenuButton
                                text={'Save'}
                                disabled={!props.hasUnsavedChanges}
                                onClick={() => {
                                    saveProjectToFirebase();
                                    // props.history.goBack();
                                }} />
                        </div>}
                        {/* <Card.Content>
                        <Card.Header>Settings</Card.Header>
                    </Card.Content> */}
                        {mode === 'settings' && <Card.Content style={{ padding: 16, }}>
                            <Form>
                                {props.table.uid !== USERS_TABLE_UID && <Form.Field>
                                    <Form.Group grouped>
                                        <label style={{ marginTop: 2 }}>Table Name</label>
                                        <Input
                                            placeholder={'Enter table name'}
                                            value={props.table.name}
                                            onChange={(e, { value }) => {
                                                props.setName(value);
                                            }}
                                        />
                                    </Form.Group>
                                </Form.Field>}
                                <Form.Field error={airtableTableIdError}>
                                    <Form.Group grouped>
                                        <div style={{ display: 'flex' }}>
                                            <label style={{ ...styles.label, marginTop: 6, marginRight: 6, }}>Table ID</label>
                                        </div>
                                        <Form.Input
                                            error={maybeGetAirtableError('tableId', props.table.airtableTableId, 'Invalid. Table ID is 17 characters long and starts with "tbl".')}
                                            placeholder={'Enter table ID'}
                                            value={airtableTableId}
                                            onChange={(e, { value }) => {
                                                props.setID(value);
                                            }}
                                        />
                                    </Form.Group>
                                </Form.Field>
                                {tableUID === DEFAULT_TABLE_UID && <Form.Field error={primaryFieldError}>
                                    <Form.Group grouped>
                                        <label style={{ marginTop: 6 }}>Primary Field Name</label>
                                        <AirtableFieldPicker
                                            value={primaryField}
                                            setValue={value => props.setPrimaryField(value)}
                                            currentTableAirtableFields={currentTableAirtableFields}
                                        />
                                    </Form.Group>
                                </Form.Field>}
                                {tableUID === DEFAULT_TABLE_UID && <Form.Field>
                                    <Form.Group grouped>
                                        <label style={{ marginTop: 6 }}>Subtitle Field Name (Optional)</label>
                                        <AirtableFieldPicker
                                            value={subtitleField}
                                            setValue={value => props.setSubtitleField(value)}
                                            currentTableAirtableFields={currentTableAirtableFields}
                                        />
                                    </Form.Group>
                                </Form.Field>}
                                {tableUID === DEFAULT_TABLE_UID && <Form.Field>
                                    <Form.Group grouped>
                                        <label style={{ marginTop: 6 }}>Thumbnail Field Name (Optional)</label>
                                        <AirtableFieldPicker
                                            value={thumbnailField}
                                            setValue={value => props.setThumbnailField(value)}
                                            currentTableAirtableFields={currentTableAirtableFields}
                                        />
                                    </Form.Group>
                                </Form.Field>}
                                {tableUID === DEFAULT_TABLE_UID && <Form.Field>
                                    <Form.Group grouped>
                                        <label style={{ marginTop: 6 }}>Form ID (Optional)</label>
                                        <Input
                                            placeholder={'Enter Form ID'}
                                            value={formId}
                                            onChange={(e, { value }) => {
                                                props.setFormId(value);
                                            }}
                                        />
                                    </Form.Group>
                                </Form.Field>}
                                {tableUID === DEFAULT_TABLE_UID && <Form.Field>
                                    <Form.Group grouped>
                                        <label style={{ marginTop: 6 }}>Search Field (Optional)</label>
                                        <AirtableFieldPicker
                                            value={searchField}
                                            setValue={value => props.setSearchField(value)}
                                            currentTableAirtableFields={currentTableAirtableFields}
                                        />
                                    </Form.Group>
                                </Form.Field>}
                                {tableUID !== USERS_TABLE_UID && <Form.Field>
                                    <Form.Group grouped>
                                        <div style={{ display: 'flex' }}>
                                            <label style={{ ...styles.label, marginTop: 6, marginRight: 6, }}>Flagging Field (Optional number field)</label>
                                        </div>
                                        <AirtableFieldPicker
                                            value={reportingField}
                                            setValue={value => props.setReportingField(value)}
                                            currentTableAirtableFields={currentTableAirtableFields}
                                        />
                                    </Form.Group>
                                </Form.Field>}
                                {/* I'm disabling the Users view id for now. Remove the false below to re-enable */}
                                {tableUID != null && false && <Form.Field>
                                    <Form.Group grouped>
                                        <label style={{ marginTop: 6 }}>Users View ID</label>
                                        <Input
                                            placeholder={'Enter Users View ID'}
                                            value={userViewId}
                                            onChange={(e, { value }) => {
                                                props.setUserViewId(value);
                                            }}
                                        />
                                    </Form.Group>
                                </Form.Field>}
                            </Form>
                        </Card.Content>}
                    </div>
                    {(mode === 'views' || mode === 'layout') && <SortableList
                        project={props.project}
                        mode={mode}
                        elements={elements}
                        onSortEnd={onSortEnd}
                        setElements={setElements}
                        addRow={props.addRow}
                        distance={20}
                        tableId={tableUID}
                        useDragHandle
                        goBackToProject={() => goBackToProject(true)}
                        tableErrors={tableErrors}
                        setHelpType={props.setHelpType}
                        currentTableAirtableFields={currentTableAirtableFields}
                        usersTableAirtableFields={usersTableAirtableFields}
                    />}
                </div >
            </div >}
        </AuthRequiredHandler >
    );
};

type ExternalProps = $ReadOnly<{
    match: $ReadOnly<{
        params: $ReadOnly<{
            projectId: string,
        }>,
    }>,
    tableId: string,
    mode: Mode,
    setHelpType?: (type: HelpType) => void,
    showTableSettingsModal?: () => void,
    showViewsModal?: () => void,
    topBarIsHidden?: boolean,
    history: $ReadOnly<{
        push: (string) => void,
        block: (*) => string,
                                                                    }>,
                                                                }>;

type PropsFromState = $ReadOnly<{
    project: ?$ReadOnly<AirtableProjectWithSecrets>,
    table: ?$ReadOnly<AirtableTable>,
    hasUnsavedChanges: boolean,
}>;

type PropsFromDispatch = $ReadOnly<{
    addRow: (elementName: ElementName) => void,
    setLayout: (layout: TableLayout) => void,
    setViews: (views: TableLayout) => void,
    setName: (name: string) => void,
    setID: (airtableTableId: string) => void,
    setFormId: (id: string) => void,
    setUserViewId: (usersViewId: string) => void,
    setPrimaryField: (primaryField: string) => void,
    setSubtitleField: (subtitleField: string) => void,
    setThumbnailField: (thumbnailField: string) => void,
    setReportingField: (reportingField: string) => void,
    setSearchField: (searchField: string) => void,
}>;

type Props = ExternalProps & PropsFromState & PropsFromDispatch;

const mapStateToProps = (state: State, ownProps: ExternalProps): PropsFromState => {
    const project: ?$ReadOnly<ProjectSaveState> = state.projects[ownProps.match.params.projectId];

    return {
        project: project != null ? project.unsaved : null,
        table: project != null ? project.unsaved.tables[ownProps.tableId] : null,
        hasUnsavedChanges: project != null ? getHasUnsavedChanges(project) : false
    };
};

const mapDispatchToProps = (dispatch: *, ownProps: ExternalProps): PropsFromDispatch => ({
    addRow: (elementName: ElementName) => {
        const action: AddLayoutElement = {
            type: 'ADD_LAYOUT_ELEMENT',
            tableId: ownProps.tableId,
            projectId: ownProps.match.params.projectId,
            elementName,
        };

        dispatch(action);
    },
    setLayout: (layout: TableLayout) => {
        const action: UpdateTableLayout = {
            type: 'UPDATE_TABLE_LAYOUT',
            tableId: ownProps.tableId,
            projectId: ownProps.match.params.projectId,
            layout,
        };

        dispatch(action);
    },
    setViews: (views: TableLayout) => {
        const action: UpdateTableViews = {
            type: 'UPDATE_TABLE_VIEWS',
            tableId: ownProps.tableId,
            projectId: ownProps.match.params.projectId,
            views,
        };

        dispatch(action);
    },
    setName: (name: string) => {
        const action: UpdateTableName = {
            type: 'UPDATE_TABLE_NAME',
            tableId: ownProps.tableId,
            projectId: ownProps.match.params.projectId,
            name,
        };

        dispatch(action);
    },
    setID: (airtableTableId: string) => {
        const action: UpdateTableId = {
            type: 'UPDATE_TABLE_ID',
            tableId: ownProps.tableId,
            projectId: ownProps.match.params.projectId,
            airtableTableId,
        };

        dispatch(action);
    },
    setFormId: (formId: string) => {
        const action: UpdateTableFormId = {
            type: 'UPDATE_TABLE_FORM_ID',
            tableId: ownProps.tableId,
            projectId: ownProps.match.params.projectId,
            formId,
        };

        dispatch(action);
    },
    setUserViewId: (userViewId: string) => {
        const action: UpdateTableUserViewId = {
            type: 'UPDATE_TABLE_USERS_VIEW_ID',
            tableId: ownProps.tableId,
            projectId: ownProps.match.params.projectId,
            userViewId,
        };

        dispatch(action);
    },
    setPrimaryField: (primaryField: string) => {
        const action: UpdateTablePrimaryField = {
            type: 'UPDATE_TABLE_PRIMARY_FIELD',
            tableId: ownProps.tableId,
            projectId: ownProps.match.params.projectId,
            primaryField,
        };

        dispatch(action);
    },
    setSubtitleField: (subtitleField: string) => {
        const action: UpdateTableSubtitleField = {
            type: 'UPDATE_TABLE_SUBTITLE_FIELD',
            tableId: ownProps.tableId,
            projectId: ownProps.match.params.projectId,
            subtitleField,
        };

        dispatch(action);
    },
    setThumbnailField: (thumbnailField: string) => {
        const action: UpdateTableThumbnailField = {
            type: 'UPDATE_TABLE_THUMBNAIL_FIELD',
            tableId: ownProps.tableId,
            projectId: ownProps.match.params.projectId,
            thumbnailField,
        };

        dispatch(action);
    },
    setReportingField: (reportingField: string) => {
        const action: UpdateTableReportingField = {
            type: 'UPDATE_TABLE_REPORTING_FIELD',
            tableId: ownProps.tableId,
            projectId: ownProps.match.params.projectId,
            reportingField,
        };

        dispatch(action);
    },
    setSearchField: (searchField: string) => {
        const action: UpdateTableSearchField = {
            type: 'UPDATE_TABLE_SEARCH_FIELD',
            tableId: ownProps.tableId,
            projectId: ownProps.match.params.projectId,
            searchField,
        };

        dispatch(action);
    },
});

const styles = {
    colorSquare: {
        height: 26,
        width: 26,
        borderRadius: 3,
        marginTop: 16,
        cursor: 'pointer',
        border: `1px solid ${grayBorderColor}`,
    },
    label: { fontSize: '.92857143em', fontWeight: 'bold' },
    alert: { display: 'flex', flexDirection: 'row' },
    alertLeftContainer: {
        width: 48,
        marginRight: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    alertLeftText: {
        fontSize: 24,
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UIEditor);
