// @flow

import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';

import { store } from './';
import firebase from './firebase';

import type { SaveProject } from "./redux/actions";
import type { ProjectSaveState } from './redux/reducers/projects';

/**
 * Saves projects to Firebase and updates store
 */
export const useSaveProject = (projectId: string) => {
    const [isProjectSaving, setIsProjectSaving] = useState(false);
    const dispatch = useDispatch();

    const saveProjectToFirebase = useCallback(
        async () => {
            const project: ?ProjectSaveState = store.getState().projects[projectId]
            if (project == null) {
                console.error('Attempting to save a project that is not in the store');
                return;
            }

            if (!isProjectSaving) {
                setIsProjectSaving(true);
            }

            try {
                await firebase.firestore().collection('projects').doc(project.saved.id).set({
                    data: project.unsaved,
                }, { merge: true });

                const action: SaveProject = {
                    type: 'SAVE_PROJECT',
                    projectId,
                };
                dispatch(action);
            } catch (error) {
                // TODO Handle error here
            } finally {
                setIsProjectSaving(false);
            }

            toast.info(
                "Shake your device to see the changes!\n\nClick to download.",
                {
                    position: 'top-right',
                    autoClose: 3000,
                    closeButton: false,
                    hideProgressBar: true,
                    closeOnClick: false,
                }
            );
        },
        [dispatch, isProjectSaving, projectId]
    )

    return {
        saveProjectToFirebase,
        isProjectSaving,
    };
};
