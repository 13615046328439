// @flow

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { useAuth } from './firebase';
import ReactLoading from 'react-loading';
import { useProject } from './GoToProject';
import { useBeforeunload } from 'react-beforeunload';
import { redirectUrlStorageKey, defaultRedirectUrl } from './LoginScreen';
import useLocalStorage from './useLocalStorage';

import type { ResetAllProjectsToSaved } from './redux/actions';
import type { GlobalState } from './redux/reducers';

type Props = {|
    children: *,
        hasUnsavedChanges ?: boolean,
        block ?: (*) => string,
            forceLoading ?: boolean,
|};

export const LEAVE_PAGE_ALERT = 'You have unsaved changes. Are you sure you want to leave this page?';

const AuthRequiredHandler = (props: Props) => {
    const auth = useAuth();
    const numOfProjects = useSelector((state: GlobalState) => Object.keys(state.projects).length);
    const dispatch = useDispatch();

    const [redirectUrl, setRedirectUrl] = useLocalStorage(redirectUrlStorageKey, defaultRedirectUrl);

    if (redirectUrl !== window.location.href) {
        setRedirectUrl(window.location.href);
    }

    useProject();

    props.block != null && props.block((location, action) => {
        return action === 'POP' && props.hasUnsavedChanges ? LEAVE_PAGE_ALERT : null;
    });
    useBeforeunload(event => props.hasUnsavedChanges ? LEAVE_PAGE_ALERT : null);

    useEffect(() => {
        const action: ResetAllProjectsToSaved = {
            type: 'RESET_ALL_PROJECTS_TO_SAVED'
        };
        dispatch(action);
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);

    const loadingComponent = <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 340,
        height: '100%',
        flexDirection: 'column'
    }}>
        <ReactLoading type={'spin'} color={'gray'} height={40} width={40} />
    </div >;

    if (props.forceLoading || auth.initializing) {
        return loadingComponent;
    } else if (!auth.user) {
        // User is not signed in.
        return <Redirect to='/login' />;
    } else if (numOfProjects === 0) {
        return loadingComponent;
    }

    return props.children;
};

export default AuthRequiredHandler;