// @flow

import { type ButtonAction } from './element-actions';

export type TextSize = 'Large' | 'Medium' | 'Small';
export const allSizes: $ReadOnlyArray<TextSize> = ['Large', 'Medium', 'Small'];
export type TextAlign = 'left' | 'right' | 'center' | 'justify';

export type ValueFieldType = 'field' | 'static-text';

export type ValueFieldBase = $ReadOnly<{|
    value: string,
|}>;

export type ValueField = $ReadOnly<{|
    ...ValueFieldBase,
    type: ValueFieldType,
|}>;

export type TextElementFields = $ReadOnly<{|
    textValue: ValueField,
        color: string,
            backgroundColor: string,
                size: TextSize,
                    textAlign: TextAlign,
|}>;

export type ButtonElementFields = $ReadOnly<{|
    +textValue: ValueField,
    +action: $ReadOnly<ButtonAction>
            |}>;

export type ImageElementFields = $ReadOnly<{|
    value: ValueField,
        height: number,
            |}>;

export type ViewElementFields = $ReadOnly<{|
    name: string,
        id: string
            |}>;

// Note: keep KeyboardStyle and allKeyboardStyles in sync
export type KeyboardStyle = 'default' | 'numeric' | 'email-address' | 'phone-pad';
export const allKeyboardStyles: $ReadOnlyArray<KeyboardStyle> = ['default', 'numeric', 'email-address', 'phone-pad'];
export type TextEditType = 'Number' | 'Text';
export const allTextEditTypes: $ReadOnlyArray<TextEditType> = ['Text', 'Number'];
export type TextEditElementFields = $ReadOnly<{|
    fieldName: string,
        placeholder: string,
            keyboardStyle ?: KeyboardStyle,
            type ?: TextEditType,
                |}>;

export type LinkedRecordsElementFields = $ReadOnly<{|
    tableUID: ?string, // The table that is being linked to.
        fieldName: string,
            primaryLookupField: string,
                subtitleLookupField: ?string,
                    imageLookupField: ?string,
                        |}>;

export type CheckboxElementFields = $ReadOnly<{|
    title: string, // shows up next to the switch
        valueFieldName: string
            |}>;

export type AudioElementFields = $ReadOnly<{|
    value: ValueField,
            |}>;

export type VideoElementFields = $ReadOnly<{|
    value: ValueField,
                        |}>;

export type TextElement = $ReadOnly<{|
    elementName: 'Text',
        fields: TextElementFields,
|}>;

export type ButtonElement = $ReadOnly<{|
    elementName: 'Button',
        fields: ButtonElementFields,
|}>;

export type ImageElement = $ReadOnly<{|
    elementName: 'Image',
        fields: ImageElementFields,
|}>;

export type ViewElement = $ReadOnly<{|
    elementName: 'View',
        fields: ViewElementFields,
|}>;

export type TextEditElement = $ReadOnly<{|
    elementName: 'Text Edit',
        fields: TextEditElementFields,
|}>;

export type LinkedRecordsElement = $ReadOnly<{|
    elementName: 'Linked Records',
        fields: LinkedRecordsElementFields,
|}>;

export type CheckboxElement = $ReadOnly<{|
    elementName: 'Checkbox',
        fields: CheckboxElementFields,
|}>;

export type AudioElement = $ReadOnly<{|
    elementName: 'Audio',
        fields: AudioElementFields,
|}>;

export type VideoElement = $ReadOnly<{|
    elementName: 'Video',
        fields: VideoElementFields,
|}>;

export type UIElement = VideoElement | AudioElement | CheckboxElement | LinkedRecordsElement | TextElement | ButtonElement | ImageElement | ViewElement | TextEditElement;
