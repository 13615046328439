// @flow

import React from "react";
import { connect } from "react-redux";

import { type AirtableProjectWithSecrets } from "./AirtableProjectWithSecrets";
import { Form, Input, Button } from "semantic-ui-react";
import type { State, ProjectSaveState } from "./redux/reducers/projects";
import firebase from "./firebase";
import AuthRequiredHandler from "./AuthRequiredHandler";
import {
  getIncompleteFieldsInProjectSettings,
  getHasUnsavedChanges
} from "./redux/utils";
import { useSaveProject } from "./useSaveProject";
import { maybeGetAirtableError } from "./airtable-utils";
import { createEmptyProject } from "./EmptyProject";

import type {
  SetProjectPushNotificationsID,
  SetProjectName,
  SetProjectBaseApiKey,
  SetProjectUserApiKey,
  SetProject
} from "./redux/actions";

const ProjectSettings = (props: $ReadOnly<Props>) => {
  const incompleteFields: Array<string> =
    props.project != null
      ? getIncompleteFieldsInProjectSettings(props.project)
      : [];
  const userApiKeyError =
    incompleteFields.indexOf("userApiKey") === -1 ? undefined : true;
  const baseApiKeyError =
    incompleteFields.indexOf("baseApiKey") === -1 ? undefined : true;

  const { saveProjectToFirebase, isProjectSaving } = useSaveProject(
    props.match.params.projectId
  );

  const baseApiKey = props.project != null ? props.project.baseApiKey : "";
  const pushNotificationsID =
    props.project != null ? props.project.pushNotificationsID : "";

  return (
    <AuthRequiredHandler
      block={props.history.block}
      hasUnsavedChanges={props.hasUnsavedChanges}
      forceLoading={isProjectSaving}
    >
      <div style={styles.container}>
        {props.project && (
          <div style={{ maxWidth: 480, width: "100%" }}>
            <div style={styles.clearButtonContainer}>
              <Button
                negative
                onClick={() => {
                  if (
                    window.confirm(
                      "Are you sure you want to delete everything and start over from scratch? \n🚨YOU CANNOT UNDO THIS! 🚨"
                    )
                  ) {
                    const emptyProject = createEmptyProject(
                      props.match.params.projectId
                    );
                    props.setEntireProject(emptyProject);
                    saveProjectToFirebase();
                  }
                }}
              >
                Clear entire project and start a new one.
              </Button>
            </div>
            <Form>
              <Form.Field>
                <Form.Group grouped>
                  <label style={{ ...styles.label, marginTop: 6 }}>
                    App Name
                  </label>
                  <Input
                    placeholder={"Enter app Name"}
                    value={props.project.name}
                    onChange={(e, { value }) => {
                      props.setName(value);
                    }}
                  />
                </Form.Group>
              </Form.Field>
              <Form.Field error={userApiKeyError}>
                <Form.Group grouped>
                  <div style={{ display: "flex" }}>
                    <label
                      style={{ ...styles.label, marginTop: 6, marginRight: 6 }}
                    >
                      User API Key
                    </label>
                  </div>

                  <Form.Input
                    placeholder={"Enter user API key"}
                    type="password"
                    name="password"
                    value={props.project.userApiKey}
                    error={maybeGetAirtableError(
                      "userApiKey",
                      props.project.userApiKey,
                      'Invalid. User API Key is 17 characters long and starts with "key".'
                    )}
                    onChange={(e, { value }) => {
                      props.setUserApiKey(value);
                    }}
                  />
                </Form.Group>
              </Form.Field>
              <Form.Field error={baseApiKeyError}>
                <Form.Group grouped>
                  <div style={{ display: "flex" }}>
                    <label
                      style={{ ...styles.label, marginTop: 6, marginRight: 6 }}
                    >
                      Base ID
                    </label>
                  </div>
                  <Form.Input
                    placeholder={"Enter base ID"}
                    value={baseApiKey}
                    error={maybeGetAirtableError(
                      "baseId",
                      baseApiKey,
                      'Invalid. Base ID is 17 characters long and starts with "app".'
                    )}
                    onChange={(e, { value }) => {
                      props.setBaseApiKey(value);
                    }}
                  />
                </Form.Group>
              </Form.Field>
              <Form.Field>
                <Form.Group grouped>
                  <div style={{ display: "flex" }}>
                    <label
                      style={{ ...styles.label, marginTop: 6, marginRight: 6 }}
                    >
                      OneSignal's Push Notifications App ID (Optional)
                    </label>
                  </div>
                  <Form.Input
                    placeholder={
                      "Enter Push Notifications App ID from OneSignal"
                    }
                    value={pushNotificationsID}
                    onChange={(e, { value }) => {
                      props.setPushNotificationsID(value);
                    }}
                  />
                </Form.Group>
              </Form.Field>
            </Form>
          </div>
        )}
        <Button
          basic
          color="red"
          style={{ marginTop: 16 }}
          onClick={() => {
            if (window.confirm("Are you sure you want to sign out?")) {
              firebase.auth().signOut();
            }
          }}
        >
          Sign out
        </Button>
      </div>
    </AuthRequiredHandler>
  );
};

type ExternalProps = $ReadOnly<{
  match: $ReadOnly<{
    params: $ReadOnly<{
      projectId: string
    }>
  }>,
  history: $ReadOnly<{
    push: string => void,
    block: (*) => string
  }>
}>;

type PropsFromState = $ReadOnly<{
  project: ?$ReadOnly<AirtableProjectWithSecrets>,
  hasUnsavedChanges: boolean
}>;

type PropsFromDispatch = $ReadOnly<{
  setName: (name: string) => void,
  setEntireProject: (project: AirtableProjectWithSecrets) => void,
  setBaseApiKey: (baseApiKey: string) => void,
  setUserApiKey: (userApiKey: string) => void,
  setPushNotificationsID: (pushNotificationsID: string) => void
}>;

type Props = ExternalProps & PropsFromState & PropsFromDispatch;

const mapStateToProps = (
  state: State,
  ownProps: ExternalProps
): PropsFromState => {
  const project: ?$ReadOnly<ProjectSaveState> =
    state.projects[ownProps.match.params.projectId];

  return {
    project: project != null ? project.unsaved : null,
    hasUnsavedChanges: project != null ? getHasUnsavedChanges(project) : false
  };
};

const mapDispatchToProps = (
  dispatch: *,
  ownProps: ExternalProps
): PropsFromDispatch => ({
  setName: (name: string) => {
    const action: SetProjectName = {
      type: "SET_PROJECT_NAME",
      projectId: ownProps.match.params.projectId,
      name
    };

    dispatch(action);
  },
  setEntireProject: (project: AirtableProjectWithSecrets) => {
    const action: SetProject = {
      type: "SET_PROJECT",
      project
    };

    dispatch(action);
  },
  setBaseApiKey: (baseApiKey: string) => {
    const action: SetProjectBaseApiKey = {
      type: "SET_PROJECT_BASE_API_KEY",
      projectId: ownProps.match.params.projectId,
      baseApiKey
    };

    dispatch(action);
  },
  setUserApiKey: (userApiKey: string) => {
    const action: SetProjectUserApiKey = {
      type: "SET_PROJECT_USER_API_KEY",
      projectId: ownProps.match.params.projectId,
      userApiKey
    };

    dispatch(action);
  },
  setPushNotificationsID: (pushNotificationsID: string) => {
    const action: SetProjectPushNotificationsID = {
      type: "SET_PROJECT_PUSH_NOTIFICATIONS_ID",
      projectId: ownProps.match.params.projectId,
      pushNotificationsID
    };

    dispatch(action);
  }
});

const styles = {
  container: {
    display: "flex",
    marginTop: 16,
    alignItems: "center",
    height: "100%",
    flexDirection: "column"
  },
  label: { fontSize: ".92857143em", fontWeight: "bold" },
  clearButtonContainer: {
    display: "flex",
    marginTop: 4,
    marginBottom: 16,
    justifyContent: "center"
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSettings);
