// @flow


import React from 'react';

import { SortableElement } from 'react-sortable-hoc';
import { TiDelete } from "react-icons/ti";
import { FaArrowAltCircleUp, FaArrowAltCircleDown } from "react-icons/fa";

import Card from 'react-bootstrap/Card';
import { Form } from 'semantic-ui-react';

type ListItemHeaderProps = {|
    deleteElement: () => void,
        moveItemUp: () => void,
            moveItemDown: () => void,
                title: string,
    |};

const ListItemHeader = ({ title, deleteElement, moveItemDown, moveItemUp }: ListItemHeaderProps) => <Card.Header
    style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        paddingRight: 8,
        paddingLeft: 14,
        paddingTop: 8,
        paddingBottom: 8,
        margin: 0,
        justifyContent: 'space-between'
    }}
>
    <h4
        style={{
            padding: 0,
            margin: 0,
            flex: 1,
            justifyContent: 'flex-start'
        }}
    >{title}</h4>
    <div style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    }}>
        <FaArrowAltCircleUp
            size={20}
            onClick={moveItemUp}
        />
        <div style={{ width: 12 }} />
        <FaArrowAltCircleDown
            size={20}
            onClick={moveItemDown}
        />
    </div>
    <div style={{
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end'
    }}>
        <TiDelete
            size={20}
            onClick={deleteElement}
        />
    </div>
</Card.Header>;

type Props = {|
    children: *,
        ...ListItemHeaderProps
    |};

const BlockCard = (props: Props) => {
    return <Card bg="light" style={{ maxWidth: 480, width: '100%', marginBottom: 24 }}>
        <ListItemHeader
            title={props.title}
            deleteElement={props.deleteElement}
            moveItemDown={props.moveItemDown}
            moveItemUp={props.moveItemUp}
        />
        <Card.Body>
            <Form>
                {props.children}
            </Form>
        </Card.Body>
    </Card>
};

export default BlockCard;
