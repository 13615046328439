// @flow

import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useAuth } from "./firebase";
import ReactLoading from "react-loading";
import { useDispatch, useSelector } from "react-redux";

import firebase from "./firebase";
import { USERS_TABLE_UID, DEFAULT_TABLE_UID } from "./shared/airtable-types";
import type { State } from "./redux/reducers/projects";
import { sampleProjectFakeApiKey } from "./shared/ids";
import type { SetProject } from "./redux/actions";
import type { AirtableProjectWithSecrets } from "./AirtableProjectWithSecrets";

type Props = $ReadOnly<{
  history: $ReadOnly<{}>
}>;

const createProject = (
  userId: string,
  setProject: (project: AirtableProjectWithSecrets) => void
) => {
  // Add a new document with a generated id.

  const now = Date.now();
  const project: AirtableProjectWithSecrets = {
    id: "temp", // this is replaced on the server
    lastUpdatedTimeStamp: Date.now(),
    name: "Untitled",
    baseApiKey: "",
    userApiKey: "",
    pushNotificationsID: null,
    tables: {
      [DEFAULT_TABLE_UID]: {
        airtableTableId: "",
        creationTimeStamp: now - 1,
        formId: "",
        searchField: null,
        layout: [],
        name: "Home",
        primaryField: "",
        reportingField: "",
        subtitleField: "",
        thumbnailField: "",
        uid: DEFAULT_TABLE_UID,
        userViewId: null,
        views: []
      },
      [USERS_TABLE_UID]: {
        airtableTableId: "",
        creationTimeStamp: now,
        formId: "",
        searchField: null,
        layout: [],
        name: "Users",
        primaryField: "",
        reportingField: null,
        subtitleField: "",
        thumbnailField: null,
        uid: USERS_TABLE_UID,
        userViewId: "",
        views: []
      }
    }
  };

  var maybeCreateNewProject = firebase
    .functions()
    .httpsCallable("maybeCreateNewProject");
  return maybeCreateNewProject({ airtableProject: project }).then(function(
    result
  ) {
    // Read result of the Cloud Function.
    setProject(result.data);
  });
};

export const useProject = () => {
  // initialize our default state
  const auth = useAuth();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hasStartedInitialLoad, setHasStartedInitialLoad] = useState(false);
  const dispatch = useDispatch();
  const project: ?AirtableProjectWithSecrets = useSelector(
    ({ projects }: { projects: State }) => {
      const allProjectIds = Object.keys(projects);
      return allProjectIds != null && allProjectIds.length > 0
        ? projects[allProjectIds[0]].saved
        : null;
    }
  );

  // when the id attribute changes (including mount)
  // subscribe to the recipe document and update
  // our state when it changes.
  useEffect(() => {
    if (
      hasStartedInitialLoad ||
      project != null ||
      auth.user == null ||
      auth.user.uid == null
    ) {
      return;
    }
    setHasStartedInitialLoad(true);
    firebase
      .firestore()
      .collection("projects")
      .where(`owner`, "==", auth.user.uid)
      .get()
      .then(async querySnapshot => {
        if (querySnapshot.docs && querySnapshot.docs.length > 0) {
          const action: SetProject = {
            type: "SET_PROJECT",
            project: querySnapshot.docs[0].data()["data"]
          };

          dispatch(action);
        } else {
          await createProject(auth.user.uid, project => {
            const action: SetProject = {
              type: "SET_PROJECT",
              project
            };

            dispatch(action);
          });
        }

        setLoading(false);
      })
      .catch(function(error) {
        setError(error);
      });
  }, [auth.user, dispatch, project, hasStartedInitialLoad]);

  return {
    error,
    loading,
    project,
    user: auth.user
  };
};

const GoToProject = (props: Props) => {
  const [redirectToProject, setRedirectToProject] = useState(false);

  const { error, project } = useProject();

  useEffect(() => {
    if (!redirectToProject && project != null) {
      setRedirectToProject(true);
    }
  }, [redirectToProject, project]);

  if (redirectToProject && project != null) {
    return <Redirect to={`/projects/${project.id}`} />;
  } else if (!error) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          flexDirection: "column"
        }}
      >
        <ReactLoading type={"spin"} color={"gray"} height={40} width={40} />
      </div>
    );
  } else {
    // TODO need better error handling here
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          flexDirection: "column"
        }}
      >
        <p>There was an error. Please try again.</p>
      </div>
    );
  }
};

export default GoToProject;
