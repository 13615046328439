/* eslint-disable jsx-a11y/anchor-is-valid */
// @flow

import { useEffect } from 'react';
import firebase from './firebase';

type ExternalProps = $ReadOnly<{
    match: $ReadOnly<{
        params: $ReadOnly<{
            email: string,
            password: string,
        }>,
    }>,
}>

const LoginReceiver = (props: ExternalProps) => {
    useEffect(() => {
        const execute = async () => {
            try {
                const user = firebase.auth().currentUser;
                if (user) {
                    await firebase.auth().signOut();
                }

                await firebase.auth().signInWithEmailAndPassword(
                    decodeURI(props.match.params.email),
                    decodeURI(props.match.params.password),
                );
            } catch (e) {
                console.error(e);
            }

            window.location.href = '/loggedUserIn';
        }
        execute();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default LoginReceiver;
