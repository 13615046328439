// @flow

type AirtableKeyType = 'userApiKey' | 'baseId' | 'tableId' | 'viewId';
export const maybeGetAirtableError = (type: AirtableKeyType, currentValue: string, message: string): ?string => {
    if (currentValue === '') {
        return null;
    }

    if (currentValue.length !== 17) {
        return message;
    }

    if (type === 'userApiKey' && !currentValue.startsWith('key')) {
        return message;
    } else if (type === 'baseId' && !currentValue.startsWith('app')) {
        return message;
    } else if (type === 'tableId' && !currentValue.startsWith('tbl')) {
        return message;
    } else if (type === 'viewId' && !currentValue.startsWith('viw')) {
        return message;
    }
    return null;
};
