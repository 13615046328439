// @flow

import type { Action } from './actions';
import type { FieldsState } from './types';

export type State = $ReadOnly<{
    [airtableTableId: string]: FieldsState,
}>;

export const initialState: State = {};

const reducer = (
    state: State = initialState,
    action: Action,
) => {
    switch (action.type) {
        case 'SET_AIRTABLE_FIELDS': {
            return {
                ...state,
                [action.airtableTableId]: action.newFieldsState,
            };
        }
        default:
            return state;
    }
};

export default reducer;
