// @flow

import React, { type Node } from 'react';
import { Card } from 'semantic-ui-react';
import { Link } from "react-router-dom";

type Props = {|
    leftText?: string,
        leftItem ?: Node,
        title: string,
            rightText: ?$ReadOnly < {|
                text: string,
                    disabled : boolean
                        |}>,
                            rightItem ?: Node,
                            leftOnClick: ?() => void,
                                rightOnClick: ?() => void,
|};

const NavBar = (props: Props) => {
    return (
        <Card.Content style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
            {props.leftText && <Link>
                <p onClick={props.leftOnClick}>{props.leftText}</p>
            </Link>}
            {props.leftItem && props.leftItem}

            <div style={{ flex: 1, }} />

            <h3 style={{ margin: 0, padding: 0 }}>{props.title}</h3>

            <div style={{ flex: 1, }} />

            {props.rightText && <Link className={props.rightText.disabled ? 'disabled-link' : undefined}>
                <p onClick={props.rightOnClick}>{props.rightText.text}</p>
            </Link>}
            {props.rightItem && props.rightItem}
        </Card.Content>
    )
}

export default NavBar;