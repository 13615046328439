/* eslint-disable jsx-a11y/anchor-is-valid */
// @flow

import React, { useState } from "react";
import { Link } from "react-router-dom";
import "antd/dist/antd.css";
import "./Tables.css";
import BootstrapBadge from "react-bootstrap/Badge";
import "bootstrap/dist/css/bootstrap.min.css";

import { Tabs } from "antd";

import Editor from "./Editor";
import { connect } from "react-redux";
import { getHasUnsavedChanges } from "./redux/utils";
import { USERS_TABLE_UID, DEFAULT_TABLE_UID } from "./shared/airtable-types";
import { MdSettings } from "react-icons/md";
import AuthRequiredHandler, { LEAVE_PAGE_ALERT } from "./AuthRequiredHandler";
import Badge from "@material-ui/core/Badge";
import { getIncompleteFieldsInProjectSettings } from "./redux/utils";
import { useSaveProject } from "./useSaveProject";
import Modal from "./Modal";
import ProjectSettings from "./ProjectSettings";
import ShareAppView from "./InviteCode/ShareAppView";
import HelpDrawer, { helpDrawerWidth, type HelpType } from "./HelpDrawer";
import { Redirect } from "react-router-dom";
import firebase from "./firebase";

import type { AirtableTable } from "./shared/airtable-types";
import type { State } from "./redux/reducers/projects";
import type { AddTable } from "./redux/actions";
import type { AirtableProjectWithSecrets } from "./AirtableProjectWithSecrets";
import type { ResetAllProjectsToSaved } from "./redux/actions";

const { TabPane } = Tabs;

const topBarColor = "#7E4DED";
export const publishIosRoute = "publish_ios";

const getTableName = (table: AirtableTable): string => {
  const maybeHome: string = table.uid === DEFAULT_TABLE_UID ? "🏠 " : "";
  const maybeProfile: string = table.uid === USERS_TABLE_UID ? "👤 " : "";
  const maybeLink: string =
    table.uid !== DEFAULT_TABLE_UID && table.uid !== USERS_TABLE_UID
      ? "🔗 "
      : "";

  return `${maybeProfile}${maybeHome}${maybeLink}${table.name}`;
};

const HeaderButton = ({
  isPill,
  disabled,
  text,
  href,
  onClick
}: {
  isPill: boolean,
  disabled?: boolean,
  text: string,
  href?: string,
  onClick?: () => void
}) => {
  const grayColor = "#E8E8E8";
  return (
    <BootstrapBadge
      style={{ backgroundColor: isPill ? grayColor : topBarColor }}
      pill
    >
      <a
        style={{ color: isPill ? topBarColor : grayColor }}
        href={!disabled ? href : undefined}
        target="_blank"
        rel="noopener noreferrer"
        onClick={onClick}
      >
        {text}
      </a>
    </BootstrapBadge>
  );
};

const homeHelpType: HelpType = "overview";

const Tables = (props: Props) => {
  // const [defaultTabKey, setDefaultTabKey] = useState('0');
  const [activeTabKey, setActiveTabKey] = useState("0");
  const [
    showViewsModalForTableId,
    setShowViewsModalForTableId
  ] = useState<?string>(null);
  const [
    showTableSettingsModalForTableId,
    setShowTableSettingsModalForTableId
  ] = useState<?string>(null);
  const [showProjectSettings, setShowProjectSettings] = useState(false);
  const [showShareAppModal, setShowShareAppModal] = useState(false);
  const project = props.project;
  const { saveProjectToFirebase, isProjectSaving } = useSaveProject(
    props.match.params.projectId
  );

  const [helpType, setHelpType] = useState<HelpType>(homeHelpType);

  /**
   * Returns true if the action that we want to take should happen
   */
  const warnUnsaved = () => {
    if (props.hasUnsavedChanges) {
      if (window.confirm(LEAVE_PAGE_ALERT)) {
        props.resetProjectsToSaved();
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const compare = (lhsTableUID: string, rhsTableUID: string) => {
    if (!project) {
      return 0;
    } else {
      const leftTime = project.tables[lhsTableUID].creationTimeStamp;
      const rightTime = project.tables[rhsTableUID].creationTimeStamp;
      return (leftTime || 0) - (rightTime || 0);
    }
  };

  const tableIds =
    project != null ? Object.keys(project.tables).sort(compare) : [];
  const content =
    project != null
      ? tableIds.map((key, index) => (
          <TabPane
            tab={getTableName(project.tables[key])}
            key={`${index}`}
            closable={false}
          >
            <Editor
              {...props}
              tableId={key}
              mode={"layout"}
              showViewsModal={() => {
                setShowViewsModalForTableId(key);
                setHelpType("views-settings");
              }}
              setHelpType={setHelpType}
              showTableSettingsModal={() => {
                setShowTableSettingsModalForTableId(key);

                if (key === USERS_TABLE_UID) {
                  setHelpType("users-table-settings");
                } else if (key === DEFAULT_TABLE_UID) {
                  setHelpType("table-settings");
                } else {
                  setHelpType("linked-records");
                }
              }}
            />
          </TabPane>
        ))
      : [];

  const pageTitle = project != null ? `${project.name} - miniAppMaker` : null;

  if (pageTitle != null && pageTitle !== document.title) {
    document.title = pageTitle;
  }

  const user = firebase.auth().currentUser;
  const showHelpbar =
    user != null ? user.uid !== "28GuYNX05tPleYyOWV0H6pS4sVo1" : true;

  const shareAppView = project != null && <ShareAppView project={project} />;

  return (
    <AuthRequiredHandler forceLoading={isProjectSaving}>
      {project == null && <Redirect to="/login" />}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          // overflow: 'hidden',
          height: "100%"
        }}
      >
        {showTableSettingsModalForTableId != null && (
          <Modal
            title={`${
              project != null
                ? project.tables[showTableSettingsModalForTableId].name
                : ""
            } Table Settings`}
            showModal={showTableSettingsModalForTableId != null}
            dismiss={() => {
              setShowTableSettingsModalForTableId(null);
              setHelpType(homeHelpType);
            }}
            projectId={props.match.params.projectId}
          >
            <Editor
              {...props}
              setHelpType={setHelpType}
              tableId={showTableSettingsModalForTableId}
              mode={"settings"}
            />
          </Modal>
        )}
        {showViewsModalForTableId != null && (
          <Modal
            title={`${
              project != null
                ? project.tables[showViewsModalForTableId].name
                : ""
            } Views`}
            showModal={showViewsModalForTableId != null}
            dismiss={() => {
              setShowViewsModalForTableId(null);
              setHelpType(homeHelpType);
            }}
            projectId={props.match.params.projectId}
          >
            <Editor
              {...props}
              setHelpType={setHelpType}
              tableId={showViewsModalForTableId}
              mode={"views"}
            />
          </Modal>
        )}
        {showProjectSettings && (
          <Modal
            title={`Project Settings`}
            showModal={showProjectSettings}
            dismiss={() => {
              setShowProjectSettings(false);
              setHelpType(homeHelpType);
            }}
            projectId={props.match.params.projectId}
          >
            <ProjectSettings {...props} />
          </Modal>
        )}
        {showShareAppModal ? (
          <Modal
            title={`Share App`}
            showModal={showShareAppModal}
            dismiss={() => {
              setShowShareAppModal(false);
              setHelpType(homeHelpType);
            }}
            hideSaveButton={true}
            projectId={props.match.params.projectId}
          >
            {shareAppView}
          </Modal>
        ) : (
          <div style={styles.hiddenShareAppView}>{shareAppView}</div>
        )}
        <div style={styles.hiddenSideContainers} />
        {project != null && (
          <div style={styles.container}>
            <div style={styles.innerContainer}>
              <div
                style={{
                  flex: 0,
                  backgroundColor: topBarColor,
                  height: 48,
                  padding: 12,
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                <div
                  style={{
                    display: "block",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    marginLeft: 26,
                    flex: 1
                  }}
                >
                  <h3
                    style={{
                      display: "block",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      color: "white",
                      textOverflow: "ellipsis"
                    }}
                  >
                    {project.name}
                  </h3>
                </div>
                <HeaderButton
                  text={"Preview"}
                  isPill={true}
                  href={"https://miniappmaker.com/download/"}
                />
                <div style={{ width: 6 }} />
                <HeaderButton
                  text={"Share"}
                  isPill={true}
                  onClick={() => {
                    setShowShareAppModal(true);
                    setHelpType("share-your-app");
                  }}
                />
                <div style={{ width: 6 }} />
                <HeaderButton
                  text={"Publish"}
                  isPill={true}
                  href={`/${publishIosRoute}/${project.id}`}
                />
                <div style={{ width: 6 }} />
                <Badge
                  color="secondary"
                  variant="dot"
                  invisible={
                    getIncompleteFieldsInProjectSettings(project).length === 0
                  }
                >
                  <Link
                    onClick={() => {
                      if (warnUnsaved()) {
                        setShowProjectSettings(true);
                        setHelpType("project-settings");
                      }
                    }}
                  >
                    <MdSettings style={{ color: "white" }} size={20} />
                  </Link>
                </Badge>
              </div>
              <Tabs
                type="editable-card"
                activeKey={activeTabKey}
                tabBarGutter={4}
                onChange={(newActiveTabKey: string) => {
                  if (warnUnsaved()) {
                    setActiveTabKey(newActiveTabKey);
                    const targetIndex = parseInt(newActiveTabKey);

                    if (tableIds.length > targetIndex) {
                      const tableId = tableIds[targetIndex];

                      switch (tableId) {
                        case USERS_TABLE_UID:
                          setHelpType("users-table-profile");
                          break;
                        case DEFAULT_TABLE_UID:
                          setHelpType("home-table");
                          break;
                        default:
                          setHelpType("linked-records");
                          break;
                      }
                    }
                  }
                }}
                onEdit={(targetKey, action) => {
                  if (action === "add") {
                    if (warnUnsaved()) {
                      props.addTable();
                      saveProjectToFirebase();

                      setActiveTabKey(`${Object.keys(project.tables).length}`);
                      setHelpType("linked-records");
                    }
                  }
                }}
                tabBarStyle={{
                  paddingRight: 6,
                  paddingLeft: 6,
                  backgroundColor: topBarColor
                }}
              >
                {content}
              </Tabs>
            </div>
          </div>
        )}
        <div style={styles.hiddenSideContainers}>
          <div className={"help_sidebar"} style={{ width: helpDrawerWidth }} />
        </div>
        {showHelpbar && (
          <div
            style={{
              zIndex: 1060,
              position: "fixed",
              right: 0,
              top: 0,
              display: "block"
            }}
          >
            <HelpDrawer type={helpType} />
          </div>
        )}
      </div>
    </AuthRequiredHandler>
  );
};

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    maxWidth: 480,
    width: "100%",
    zIndex: 10,
    flexShrink: 0,
    backgroundColor: "clear"
  },
  innerContainer: {
    width: "100%",
    borderRight: "1px solid #cccccc",
    borderLeft: "1px solid #cccccc",
    borderBottom: "1px solid #cccccc"
  },
  cellContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row"
  },
  hiddenSideContainers: {
    display: "flex",
    flexShrink: 1,
    visibility: "hidden",
    width: "50%",
    zIndex: 0
  },
  hiddenShareAppView: {
    osition: "absolute",
    visibility: "hidden",
    height: 0,
    width: 0
  }
};

type ExternalProps = $ReadOnly<{
  match: $ReadOnly<{
    params: $ReadOnly<{
      projectId: string
    }>
  }>
}>;

type PropsFromState = $ReadOnly<{
  project: ?$ReadOnly<AirtableProjectWithSecrets>,
  hasUnsavedChanges: boolean
}>;

type PropsFromDispatch = $ReadOnly<{
  addTable: () => void,
  resetProjectsToSaved: () => void
}>;

type Props = $ReadOnly<ExternalProps & PropsFromState & PropsFromDispatch>;

const mapStateToProps = (
  state: State,
  ownProps: ExternalProps
): PropsFromState => {
  const project = state.projects[ownProps.match.params.projectId];

  return {
    project: project != null ? project.unsaved : null,
    hasUnsavedChanges: project != null ? getHasUnsavedChanges(project) : false
  };
};

const mapDispatchToProps = (
  dispatch: *,
  ownProps: ExternalProps
): PropsFromDispatch => ({
  addTable: () => {
    const action: AddTable = {
      type: "ADD_TABLE",
      projectId: ownProps.match.params.projectId
    };
    dispatch(action);
  },
  resetProjectsToSaved: () => {
    const action: ResetAllProjectsToSaved = {
      type: "RESET_ALL_PROJECTS_TO_SAVED"
    };
    dispatch(action);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Tables);
