// @flow 

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router-dom';

import { Provider } from 'react-redux';
import { createStore } from 'redux';
import rootReducer from './redux/reducers';
import 'bootstrap/dist/css/bootstrap.min.css';

import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

ReactGA.initialize('UA-148621098-2');
var history = createBrowserHistory();

history.listen(function (location) {
    ReactGA.pageview(window.location.pathname + window.location.search);
});

const tagManagerArgs = {
    gtmId: 'GTM-TXQ48CK',
};

TagManager.initialize(tagManagerArgs);

export const store = createStore(rootReducer);

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <App />
        </Router>
    </Provider>
    ,
    // $FlowFixMe
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
