// @flow

import React from 'react';

import { Dropdown, } from 'semantic-ui-react';

import './TextValueEditor.css';

import type { Fields } from './redux/reducers/airtableFields/types';

type Props = {|
    value: ?string,
        setValue: (string) => void,
            currentTableAirtableFields: Fields,
                removeLeftBorderRadius ?: boolean
                    |};

const AirtableFieldPicker = (props: Props) => {
    const fieldsInDropdown = props.value
        && props.currentTableAirtableFields.indexOf(props.value) === -1 ?
        props.currentTableAirtableFields.concat([props.value]) :
        props.currentTableAirtableFields;

    return (
        <Dropdown
            className={props.removeLeftBorderRadius ? 'noBorderRadius' : undefined}
            options={fieldsInDropdown.map(field => ({
                key: field,
                text: field,
                value: field,
            }))}
            placeholder='Choose Airtable field'
            search
            selection
            selectOnBlur={false}
            fluid
            allowAdditions
            additionLabel='Field: '
            noResultsMessage={'Type an Airtable field name'}
            value={props.value ? props.value : null}
            onAddItem={(e, { value }: { value: string }) => {
                props.setValue(value)
            }}
            onChange={(e, { value }: { value: string }) => {
                props.setValue(value)
            }}
        />
    );
};

export default AirtableFieldPicker;
