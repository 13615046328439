// @flow

import React from 'react';

type Props = {|
|}

const ProjectSetup = (props: Props) => {
    return <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        flexDirection: 'column'
    }}>
        <p>Project setup</p>
    </div >;
};

export default ProjectSetup;