// @flow

import React from 'react';

import { connect } from 'react-redux';
import AuthRequiredHandler from '../AuthRequiredHandler';
import { useSaveProject } from '../useSaveProject';
import Markdown from 'react-markdown';
import { Button, Card } from 'semantic-ui-react';
import NavBar from '../NavBar';

import type { State } from '../redux/reducers/projects';
import type { AirtableProject } from '../shared/airtable-types';
import type { AirtableProjectWithSecrets } from '../AirtableProjectWithSecrets';

const getProjectWithoutSecrets = (projectWithSecrets: AirtableProjectWithSecrets): AirtableProject => {
    const { baseApiKey: _, userApiKey: __, inviteCode: ____, ...projectWithoutSecrets } = projectWithSecrets;
    return projectWithoutSecrets;
}

const downloadProjectJSON = (filename: string, text: string) => {
    if (document.body == null) {
        // TODO handle error better
        return alert('Could not download file.');
    }
    const body = document.body;

    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    body.appendChild(element);

    element.click();

    body.removeChild(element);
};


const ExportIOSScreen = (props: Props) => {
    const project = props.project;
    const projectWithoutSecrets = project != null ? getProjectWithoutSecrets(project) : null;
    const {
        isProjectSaving
    } = useSaveProject(props.match.params.projectId);

    return (
        <AuthRequiredHandler forceLoading={isProjectSaving}>
            <div style={styles.container}>
                <Card style={{ maxWidth: 680, width: '100%' }}>
                    <NavBar
                        leftText={'Back'}
                        title={'Submit to the App Store'}
                        rightText={{
                            text: '    ',
                            disabled: true,
                        }}
                        leftOnClick={() => {
                            props.history.push(`/projects/${props.match.params.projectId}`)
                        }}
                        rightOnClick={null}
                    />
                    <Card.Content>
                        <Markdown source={`
### Step 1: Download Xcode`
                        } />
                        <Button
                            primary
                            href={'https://apps.apple.com/us/app/xcode/id497799835?mt=12'}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Download Xcode
            </Button>
                        <Markdown source={`
### Step 2: Download Xcode Project`
                        } />
                        <Button
                            primary
                            href={'https://firebasestorage.googleapis.com/v0/b/app-maker-88a2b.appspot.com/o/Xcode%20Project%201.01.zip?alt=media&token=b77c3026-8644-4745-8abc-5c71d511937b'}
                        >
                            Download Xcode Project
            </Button>
                        <Markdown source={`
### Step 3: Download Your App Data`
                        } />
                        <Button
                            primary
                            onClick={() => {
                                downloadProjectJSON("app_data.json", JSON.stringify({
                                    type: 'loaded',
                                    data: projectWithoutSecrets
                                }));
                            }}
                        >
                            Download App Data
            </Button>
                        <Markdown source={`
### Step 4: Complete Tutorial`
                        } />
                        <Button
                            primary
                            href={'https://miniappmaker.com/help/uncategorized/publish-to-app-store/'}
                        >
                            Go to Tutorial
                        </Button>
                    </Card.Content>
                </Card>
            </div >
        </AuthRequiredHandler >
    );
};

const styles = {
    container: {
        display: 'flex',
        marginTop: 16,
        alignItems: 'center',
        height: '100%',
        flexDirection: 'column'
    },
    image: { maxWidth: '100%', maxHeight: '100%' },
};

type ExternalProps = $ReadOnly<{
    match: $ReadOnly<{
        params: $ReadOnly<{
            projectId: string,
        }>,
    }>,
    history: $ReadOnly<{
        push: (string) => void,
    }>;
}>;

type PropsFromState = $ReadOnly<{
    project: ?$ReadOnly<AirtableProjectWithSecrets>,
}>;

type PropsFromDispatch = $ReadOnly<{
}>;

type Props = $ReadOnly<ExternalProps & PropsFromState & PropsFromDispatch>;

const mapStateToProps = (state: State, ownProps: ExternalProps): PropsFromState => {
    const project = state.projects[ownProps.match.params.projectId];

    return {
        project: project != null ? project.unsaved : null,
    };
};

const mapDispatchToProps = (dispatch: *, ownProps: ExternalProps): PropsFromDispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExportIOSScreen);