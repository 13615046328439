// @flow

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Tables from './Tables';
import LoginScreen from './LoginScreen';
import PageNotFound from './PageNotFound';
import ProjectSetup from './ProjectSetup';
import GoToProject from './GoToProject';
import ExportIOSScreen from './ExportIOSScreen/index';
import SingleTableEditor from './SingleTableEditor';
import LoginReceiver from './LoginReceiver';

import { publishIosRoute } from './Tables';

const Main = () => {
    return (
        <Switch>
            {/* <Route exact path='/' component={Editor}></Route> */}
            <Route exact path='/' component={LoginScreen}></Route>
            <Route exact path='/login' component={LoginScreen}></Route>
            <Route exact path='/project_setup' component={ProjectSetup}></Route>
            <Route exact path='/go_to_project' component={GoToProject}></Route>
            <Route exact path={`/${publishIosRoute}/:projectId`} component={ExportIOSScreen}></Route>

            <Route path='/projects/:projectId/:mode/:tableId' component={SingleTableEditor}></Route>
            <Route path='/login-receiver/:email/:password' component={LoginReceiver}></Route>

            <Route path='/projects/:projectId' component={Tables}></Route>

            <Route path='/saved' component={<div />}></Route>
            <Route path='/loggedUserIn' component={<div />}></Route>

            <Route component={PageNotFound}></Route>
        </Switch>
    );
}

export default Main;