// @flow

import React, { type Node } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactLoading from 'react-loading';
import { useSelector, useDispatch } from "react-redux";
import './Modal.css';

import { useSaveProject } from './useSaveProject';
import { getHasUnsavedChanges } from './redux/utils';
import { LEAVE_PAGE_ALERT } from './AuthRequiredHandler';

import type { ResetAllProjectsToSaved } from './redux/actions';
import type { State, ProjectSaveState } from './redux/reducers/projects';

export type Props = $ReadOnly<{|
    title: string,
        showModal: boolean,
            hideSaveButton ?: boolean,
            dismiss: () => void,
                children: Node,
                    projectId: string,
                    |}>;

const OurModal = (props: Props) => {
    const {
        saveProjectToFirebase,
        isProjectSaving,
    } = useSaveProject(props.projectId);
    const dispatch = useDispatch();

    const hasUnsavedChanges = useSelector((state: State) => {
        const project: ?$ReadOnly<ProjectSaveState> = state.projects[props.projectId];
        return project != null ? getHasUnsavedChanges(project) : false;
    });

    const loadingComponent = <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 340,
        height: '100%',
        flexDirection: 'column'
    }}>
        <ReactLoading type={'spin'} color={'gray'} height={40} width={40} />
    </div >;

    const removeUnsavedChangesAndDismiss = () => {
        if (hasUnsavedChanges) {
            if (window.confirm(LEAVE_PAGE_ALERT)) {
                const action: ResetAllProjectsToSaved = {
                    type: 'RESET_ALL_PROJECTS_TO_SAVED',
                }
                dispatch(action);
                props.dismiss();
            }
        } else {
            const action: ResetAllProjectsToSaved = {
                type: 'RESET_ALL_PROJECTS_TO_SAVED',
            }
            dispatch(action);
            props.dismiss();
        }
    }

    if (!props.showModal) {
        return null;
    }

    return (
        <div>
            <div
                style={styles.backdrop}
            />
            <Modal
                modalClassName={'modalLayout'}
                backdrop={false}
                style={{ paddingRight: 24, paddingLeft: 24 }}
                centered={true}
                isOpen={props.showModal}
                toggle={removeUnsavedChangesAndDismiss}
                zIndex={1070}
            >
                {isProjectSaving ? loadingComponent : <div>
                    <ModalHeader toggle={removeUnsavedChangesAndDismiss}><h2>{props.title}</h2></ModalHeader>
                    {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
                    <ModalBody style={{ maxHeight: 600, overflowY: 'scroll' }}>
                        {props.children}
                    </ModalBody>
                    {!!!props.hideSaveButton && <ModalFooter>
                        <Button
                            disabled={!hasUnsavedChanges}
                            color={hasUnsavedChanges ? "primary" : "secondary"}
                            onClick={saveProjectToFirebase}
                        >Save</Button>
                    </ModalFooter>}
                </div>}
            </Modal>
        </div>
    );
}

const styles = {
    backdrop: { zIndex: 1040, width: '100%', height: '100%', opacity: 0.6, backgroundColor: 'black', position: 'absolute' }
}

export default OurModal;