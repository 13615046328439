// @flow

import firebase from '../firebase';

import type { AirtableProjectWithSecrets } from '../AirtableProjectWithSecrets';

export const getInviteCode = async (project: AirtableProjectWithSecrets): Promise<string> => {
    const inviteCode = project.inviteCode;

    if (inviteCode != null) {
        return inviteCode;
    } else {
        return createInviteCodeForProject(project);
    }
};

const createInviteCodeForProject = async (project: AirtableProjectWithSecrets): Promise<string> => {
    if (project.inviteCode != null) {
        throw new Error('Project already contains an invite code. Will not create a new one.');
    }

    try {
        var generateInviteCodeForProject = firebase.functions().httpsCallable('generateInviteCodeForProject');
        const result = await generateInviteCodeForProject({ projectId: project.id })

        if (result != null && result.data != null) {
            debugger
            return result.data;
        } else {
            return Promise.reject(new Error('Could not create invite user.'));
        }
    } catch (error) {
        return Promise.reject(error);
    }
}
