// @flow

import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { useDispatch } from 'react-redux';
import { Form, TextArea } from 'semantic-ui-react';

import { getInviteCode } from './api';

import type { AirtableProjectWithSecrets } from '../AirtableProjectWithSecrets';
import type { SetProjectInviteCode } from '../redux/actions';

type Props = $ReadOnly<{|
    project: AirtableProjectWithSecrets
        |}>;

type InviteCode = {
    type: 'not-loaded'
} | {
    type: 'loading'
} | {
    type: 'loaded',
    code: string,
} | {
    type: 'failed'
};

const ShareAppView = (props: Props) => {
    const [inviteCode, setInviteCode] = useState<InviteCode>({ type: 'not-loaded' });
    const dispatch = useDispatch();

    useEffect(() => {
        if (inviteCode.type === 'not-loaded') {
            setInviteCode({
                type: 'loading'
            });

            getInviteCode(props.project)
                .then((code: string) => {
                    setInviteCode({
                        type: 'loaded',
                        code,
                    });

                    if (props.project.inviteCode !== inviteCode) {
                        const action: SetProjectInviteCode = {
                            type: 'SET_PROJECT_INVITE_CODE',
                            projectId: props.project.id,
                            inviteCode: code,
                        }
                        dispatch(action);
                    }
                })
                .catch(function (error) {
                    setInviteCode({
                        type: 'failed'
                    });
                });
            ;
        }
    }, [dispatch, inviteCode, props.project]);

    return (
        <div style={styles.container}>
            {
                (inviteCode.type === 'loading' || inviteCode.type === 'not-loaded') && <div style={styles.centeringContainer}>
                    <ReactLoading type={'spin'} color={'gray'} height={40} width={40} />
                </div >
            }
            {
                inviteCode.type === 'loaded' && <div style={{
                    margin: 16,
                    display: 'flex',
                    alignItems: 'flex-start',
                    height: '100%',
                    width: '100%',
                    justifyContent: 'flex-start',
                }}>
                    <Form>

                        <label style={{ marginBottom: 12 }}>Share this code with anyone to show them the app you are building!</label>
                        <Form.Field
                            style={{
                                // display: 'flex',
                                // flexDirection: 'row',
                            }}>
                            <Form.Group grouped>
                                <label style={{ marginTop: 0 }}>Invite Code</label>
                                <TextArea
                                    placeholder={'Invite Code'}
                                    value={inviteCode.code}
                                    disabled={false}
                                    focus={false}
                                    rows="3"
                                    style={{
                                        resize: 'none'
                                    }}
                                // size={'large'}
                                />
                            </Form.Group>
                            <Form.Group grouped>
                                <label style={{ marginTop: 0 }}>Invitation message to share</label>
                                <TextArea
                                    placeholder={'Invite Code'}
                                    value={`Please go to http://miniAppMaker.com/download to get the app viewer, then paste this code:\n${inviteCode.code}\n\nThanks!`}
                                    disabled={false}
                                    focus={false}
                                    rows="10"
                                    style={{
                                        resize: 'none'

                                    }}
                                />
                            </Form.Group>

                        </Form.Field >
                    </Form>
                </div >
            }
            {
                inviteCode.type === 'failed' && <div style={styles.centeringContainer}>
                    <div>{`Loading invite code failed.`}</div>
                </div >
            }
        </div>
    )
}

const styles = {
    container: {
        height: 400,
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    centeringContainer: {
        margin: 16,
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
    },
};

export default ShareAppView;
