/* eslint-disable jsx-a11y/anchor-is-valid */
// @flow

import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import './Tables.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Editor from './Editor';
import { connect } from 'react-redux';
import { getHasUnsavedChanges } from './redux/utils';
import AuthRequiredHandler from './AuthRequiredHandler';
import { useSaveProject } from './useSaveProject';
import { Redirect } from "react-router-dom";

import type { State } from './redux/reducers/projects';
import { DEFAULT_TABLE_UID } from './shared/airtable-types';
import type { AirtableProjectWithSecrets } from './AirtableProjectWithSecrets';

const SingleTableEditor = (props: Props) => {
    // const [defaultTabKey, setDefaultTabKey] = useState('0');
    const project = props.project;
    const {
        saveProjectToFirebase,
        isProjectSaving
    } = useSaveProject(props.match.params.projectId);

    const [showTableSettings, setShowTableSettings] = useState(false);

    useEffect(() => {
        const onMessage = async (event: any) => {
            if (event.data === "saveSingleTableEditor") {
                await saveProjectToFirebase();
                window.location.href = '/saved';
            }
        }

        window.addEventListener("message", onMessage, false);

        return () => window.removeEventListener("message", onMessage);
    }, [saveProjectToFirebase]);

    const pageTitle = project != null ? `${project.name} - miniAppMaker` : null;

    if (pageTitle != null && pageTitle !== document.title) {
        document.title = pageTitle;
    }

    if (props.match.params.mode === 'views' && props.match.params.tableId !== DEFAULT_TABLE_UID) {
        return <div>
            Page Not Found
        </div>;
    }

    return (
        <AuthRequiredHandler forceLoading={isProjectSaving}>
            {project == null && <Redirect to='/login' />}
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                width: '100%',
                height: '100%'
            }}>
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    maxWidth: 480,
                    width: '100%',
                    flex: 1,
                    zIndex: 10,
                    flexShrink: 0,
                    backgroundColor: 'clear',
                }}>
                    {!showTableSettings && props.match.params.mode === 'layout' && <div
                        style={{ paddingTop: 16, paddingBottom: 8 }}>
                        <a
                            href={'#'}
                            onClick={() => setShowTableSettings(true)}
                        >
                            Show table settings
                        </a>
                    </div>}
                    {showTableSettings && <div style={{ width: '100%', marginBottom: 16, }}>
                        <Editor
                            {...props}
                            topBarIsHidden={true}
                            tableId={props.match.params.tableId}
                            mode={'settings'}
                        /></div>}
                    <Editor
                        {...props}
                        topBarIsHidden={true}
                        tableId={props.match.params.tableId}
                        mode={props.match.params.mode}
                    />
                </div>
            </div>
        </AuthRequiredHandler >
    );
};

type ExternalProps = $ReadOnly<{
    match: $ReadOnly<{
        params: $ReadOnly<{
            projectId: string,
            tableId: string,
            mode: string,
        }>,
    }>,
}>;

type PropsFromState = $ReadOnly<{
    project: ?$ReadOnly<AirtableProjectWithSecrets>,
    hasUnsavedChanges: boolean,
}>;

type PropsFromDispatch = $ReadOnly<{
}>;

type Props = $ReadOnly<ExternalProps & PropsFromState & PropsFromDispatch>;

const mapStateToProps = (state: State, ownProps: ExternalProps): PropsFromState => {
    const project = state.projects[ownProps.match.params.projectId];

    return {
        project: project != null ? project.unsaved : null,
        hasUnsavedChanges: project != null ? getHasUnsavedChanges(project) : false,
    };
};

const mapDispatchToProps = (dispatch: *, ownProps: ExternalProps): PropsFromDispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SingleTableEditor);