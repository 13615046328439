// @flow

import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "./firebase";
import useLocalStorage from "./useLocalStorage";

export const redirectUrlStorageKey = "tempRedirectLoginUrl";
export const defaultRedirectUrl = "/go_to_project";

const LoginScreen = () => {
  // eslint-disable-next-line no-unused-vars
  const [redirectUrl, setRedirectUrl] = useLocalStorage<string>(
    redirectUrlStorageKey,
    defaultRedirectUrl
  );

  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
    callbacks: {
      signInSuccess: () => {
        // Following line wasn't working
        // window.location.href = redirectUrl;

        window.location.href = defaultRedirectUrl;
        setRedirectUrl(defaultRedirectUrl);
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
    </div>
  );
};

export default LoginScreen;
