// @flow

import { combineReducers } from 'redux';
import projects from './projects';
import airtableFields from './airtableFields';

import type { State as ProjectsState } from './projects';
import type { State as AirtableFieldsState } from './airtableFields';

export type GlobalState = $ReadOnly<{|
    projects: ProjectsState,
        airtableFields: AirtableFieldsState,
|}>;

export default combineReducers({
    projects,
    airtableFields,
});
