// @flow

import type { ElementName, AirtableTable } from '../shared/airtable-types';
import { DEFAULT_TABLE_UID, USERS_TABLE_UID } from "../shared/airtable-types";
import type { CheckboxElement, TextElement, ButtonElement, ViewElement, ImageElement, TextEditElement, LinkedRecordsElement, AudioElement, VideoElement } from '../shared/ui-elements-types';
import type { AirtableProjectWithSecrets } from '../AirtableProjectWithSecrets';
import type { ProjectSaveState } from './reducers/projects';

const initialTextElement: TextElement = {
    elementName: 'Text',
    fields: {
        textValue: {
            type: 'field',
            value: '',
        },
        color: 'black',
        backgroundColor: 'white',
        size: 'Medium',
        textAlign: 'center'
    },
};

const initialButtonElement: ButtonElement = {
    elementName: 'Button',
    fields: {
        textValue: {
            type: 'static-text',
            value: '',
        },
        action: {
            type: 'NONE'
        },
    },
};

const initialViewElement: ViewElement = {
    elementName: 'View',
    fields: {
        name: '',
        id: ''
    },
};

const initialImageElement: ImageElement = {
    elementName: 'Image',
    fields: {
        value: {
            type: 'field',
            value: '',
        },
        height: 100,
    },
};

const initialTextEditElement: TextEditElement = {
    elementName: 'Text Edit',
    fields: {
        fieldName: '',
        placeholder: '',
        keyboardStyle: 'default'
    },
};

const initialCheckboxElement: CheckboxElement = {
    elementName: 'Checkbox',
    fields: {
        title: '',
        valueFieldName: '',
    },
};

const initialLinkedRecordsElement: LinkedRecordsElement = {
    elementName: 'Linked Records',
    fields: {
        tableUID: null,
        fieldName: '',
        primaryLookupField: '',
        subtitleLookupField: null,
        imageLookupField: null,
    },
};

const initialAudioElement: AudioElement = {
    elementName: 'Audio',
    fields: {
        value: {
            type: 'field',
            value: '',
        },
    },
};

const initialVideoElement: VideoElement = {
    elementName: 'Video',
    fields: {
        value: {
            type: 'field',
            value: '',
        },
    },
};

export const getInitialElementFromElementName = (name: ElementName) => {
    switch (name) {
        case 'Text':
            return initialTextElement;
        case 'Button':
            return initialButtonElement;
        case 'Image':
            return initialImageElement;
        case 'View':
            return initialViewElement;
        case 'Text Edit':
            return initialTextEditElement;
        case 'Checkbox':
            return initialCheckboxElement;
        case 'Linked Records':
            return initialLinkedRecordsElement;
        case 'Audio':
            return initialAudioElement;
        case 'Video':
            return initialVideoElement;
        default:
            /* eslint-disable no-unused-expressions */
            (name: empty);
            throw new Error(`Unknown ElementName: ${name}`);
    }
};

export const getIncompleteFieldsInTableSettings = (table: AirtableTable) => {
    const fields: Array<string> = [];

    if (!table.airtableTableId || table.airtableTableId === '') {
        fields.push('airtableTableId');
    }

    if (table.uid === DEFAULT_TABLE_UID && (!table.primaryField || table.primaryField === '')) {
        fields.push('primaryField');
    }

    if (table.uid === DEFAULT_TABLE_UID && table.views.length === 0) {
        fields.push('views');
    }

    return fields
};

export const getIncompleteFieldsInProjectSettings = (project: AirtableProjectWithSecrets) => {
    const fields: Array<string> = [];

    if (!project.baseApiKey || project.baseApiKey === '') {
        fields.push('baseApiKey');
    }

    if (!project.userApiKey || project.userApiKey === '') {
        fields.push('userApiKey');
    }

    return fields
};

export type TableError = 'non-default-table-not-linked';
// This is not for settings error. For that, look at getIncompleteFieldsInTableSettings
export const getErrorsForTable = (project: AirtableProjectWithSecrets, targetTable: AirtableTable): $ReadOnlyArray<TableError> => {
    const result: Array<TableError> = [];

    if (targetTable.uid !== DEFAULT_TABLE_UID && targetTable.uid !== USERS_TABLE_UID) {
        let isLinked = false;
        const tableUIDs = Object.keys(project.tables);
        for (let i = 0; i < tableUIDs.length && !isLinked; i++) {
            const tableUID = tableUIDs[i];

            if (tableUID !== targetTable.uid) {
                // Null if this table doesn't have a linked records element
                // that links to target table.
                const linkedRecord = project.tables[tableUID].layout.find(uiElement => {
                    return uiElement.elementName === 'Linked Records' && uiElement.fields.tableUID === targetTable.uid;
                });

                if (linkedRecord != null) {
                    isLinked = true;
                }
            }
        }

        if (!isLinked) {
            result.push('non-default-table-not-linked');
        }
    }

    return result;
};

export const getTableErrorMessage = (error: TableError): string => {
    if (error === 'non-default-table-not-linked') {
        return 'To display records from this table, you must link to it from another table using a "Linked Records" element.';
    } else {
        /* eslint-disable no-unused-expressions */
        (error: empty);
        throw new Error(`Unknown table error type: ${error}`);
    }
};

export const guidGenerator = () => {
    var S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
};

export const getHasUnsavedChanges = (project: ProjectSaveState) => {
    return project.unsaved.lastUpdatedTimeStamp !== project.saved.lastUpdatedTimeStamp;
};
