
// @flow

import type { ValueField } from "./ui-elements-types";
export type actionType = 'NONE' | 'LINK_CURRENT_USER_RECORD' | 'UNLINK_CURRENT_USER_RECORD' | 'URL';

export const titleForActionType = (type: actionType) => {
    if (type === 'NONE') {
        return 'Choose an action';
    } else if (type === 'LINK_CURRENT_USER_RECORD') {
        return 'Link record';
    } else if (type === 'UNLINK_CURRENT_USER_RECORD') {
        return 'Unlink record';
    } else if (type === 'URL') {
        return 'URL';
    } else {
        /* eslint-disable no-unused-expressions */
        (type: empty);
        throw new Error(`Unknown actionType: ${type}`);
    }
};

export type LinkCurrentUserAction = $ReadOnly<{
    type: 'LINK_CURRENT_USER_RECORD',
    fieldName: string,
}>;

export type UnlinkCurrentUserAction = $ReadOnly<{
    type: 'UNLINK_CURRENT_USER_RECORD',
    fieldName: string,
}>;

export type URLAction = $ReadOnly<{
    type: 'URL',
    url: ValueField,
}>;

/**
 * Field name here would be of the one that's in the user's table.
 */
export type ButtonAction = $ReadOnly<{
    type: 'NONE',
}> | LinkCurrentUserAction | UnlinkCurrentUserAction | URLAction;

export const actionForTitle = (title: string): ButtonAction => {
    const type = allActionTypes.find(type => titleForActionType(type) === title);

    if (!type) {
        throw new Error(`actionForTitle failed due to an unknown title.`);
    }

    switch (type) {
        case 'NONE':
            return {
                type: 'NONE',
            };
        case 'LINK_CURRENT_USER_RECORD':
            return {
                type: 'LINK_CURRENT_USER_RECORD',
                fieldName: '',
            };
        case 'UNLINK_CURRENT_USER_RECORD':
            return {
                type: 'UNLINK_CURRENT_USER_RECORD',
                fieldName: '',
            };
        case 'URL':
            return {
                type: 'URL',
                url: {
                    type: 'field',
                    value: ''
                },
            };
        default:
            /* eslint-disable no-unused-expressions */
            (type: empty);
            throw new Error(`Unknown actionType: ${type}`);
    }
};

export const allActionTypes: Array<actionType> = ['NONE', 'LINK_CURRENT_USER_RECORD', 'UNLINK_CURRENT_USER_RECORD', 'URL'];

