// @flow

import Airtable from 'airtable';

import type { Fields } from './types';
import type { Action, SetAirtableFields } from './actions';

export const fetchAirtableFields = async (
    airtableApiKey: string,
    airtableBaseId: string,
    airtableTableId: string,
    dispatch: Action => void
) => {
    const loadingAction: SetAirtableFields = { type: 'SET_AIRTABLE_FIELDS', airtableTableId, newFieldsState: { type: 'loading' } };

    dispatch(loadingAction);

    var table = new Airtable({ apiKey: airtableApiKey }).base(airtableBaseId)(airtableTableId);

    try {
        const records = await table.select({
            pageSize: 100,
        }).firstPage();

        const nonUniquefields: Fields = records.map(record => Object.keys(record.fields)).flat();
        const uniqueFields: Fields = Array.from(new Set(nonUniquefields));

        const loadedAction: SetAirtableFields = { type: 'SET_AIRTABLE_FIELDS', airtableTableId, newFieldsState: { type: 'loaded', fields: uniqueFields, airtableApiKey, airtableBaseId } };
        dispatch(loadedAction);
    } catch {
        const failedAction: SetAirtableFields = { type: 'SET_AIRTABLE_FIELDS', airtableTableId, newFieldsState: { type: 'failed', airtableApiKey, airtableBaseId } };
        dispatch(failedAction);
    }
};
