// @flow

/**
 * The is used for the users table for all projects
 */

//  TODO: rename this file. I use it not to store constants.

module.exports = {
    usersId: 'users',
    defaultTableId: '12345',
    sampleProjectFakeApiKey: 'keyXXXXXXXXXXXXXX',
    invitedUserEmailPrefix: 'inviteduser.',
};
