// @flow

import { useEffect, useState } from 'react';

import firebase from 'firebase';
import 'firebase/functions';
const config = {
    apiKey: "AIzaSyBGr63PkslcLXiVWxY2ysEAMan2BmR-_vY",
    authDomain: "app-maker-88a2b.firebaseapp.com",
    databaseURL: "https://app-maker-88a2b.firebaseio.com",
    projectId: "app-maker-88a2b",
    storageBucket: "",
    messagingSenderId: "799206228265",
    appId: "1:799206228265:web:f1be1c171b169585"
};
firebase.initializeApp(config);
firebase.functions();


export const useAuth = () => {
    const [state, setState] = useState(() => {
        const user = firebase.auth().currentUser;
        return { initializing: !user, user, };
    });
    function onChange(user) {
        setState({ initializing: false, user })
    }

    useEffect(() => {
        // listen for auth state changes
        const unsubscribe = firebase.auth().onAuthStateChanged(onChange)
        // unsubscribe to the listener when unmounting
        return () => unsubscribe()
    }, [])

    return state
};

export default firebase;