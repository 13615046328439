// @flow

import type { Action } from '../actions';
import { type AirtableProjectWithSecrets } from '../../AirtableProjectWithSecrets';
import { getInitialElementFromElementName, guidGenerator } from '../utils';
import type { AirtableTable } from '../../shared/airtable-types';

export type ProjectSaveState = $ReadOnly<{
    saved: AirtableProjectWithSecrets,
    unsaved: AirtableProjectWithSecrets
}>;
export type State = $ReadOnly<{
    [id: string]: ProjectSaveState,
}>;

export const initialState: State = {};

const reducer = (
    state: State = initialState,
    action: Action,
) => {
    switch (action.type) {
        case 'UPDATE_TABLE_LAYOUT':
            const project = state[action.projectId];

            return {
                ...state,
                [action.projectId]: {
                    ...project,
                    unsaved: {
                        ...project.unsaved,
                        lastUpdatedTimeStamp: Date.now(),
                        tables: {
                            ...project.unsaved.tables,
                            [action.tableId]: {
                                ...project.unsaved.tables[action.tableId],
                                layout: action.layout,
                            }
                        }
                    }
                }
            };
        case 'UPDATE_TABLE_VIEWS':
            const projectToUpdate = state[action.projectId];

            return {
                ...state,
                [action.projectId]: {
                    ...projectToUpdate,
                    unsaved: {
                        ...projectToUpdate.unsaved,
                        lastUpdatedTimeStamp: Date.now(),
                        tables: {
                            ...projectToUpdate.unsaved.tables,
                            [action.tableId]: {
                                ...projectToUpdate.unsaved.tables[action.tableId],
                                views: action.views,
                            }
                        }
                    }
                }
            };
        case 'UPDATE_TABLE_NAME':
            const projectToUpdateTableName = state[action.projectId];

            return {
                ...state,
                [action.projectId]: {
                    ...projectToUpdateTableName,
                    unsaved: {
                        ...projectToUpdateTableName.unsaved,
                        lastUpdatedTimeStamp: Date.now(),
                        tables: {
                            ...projectToUpdateTableName.unsaved.tables,
                            [action.tableId]: {
                                ...projectToUpdateTableName.unsaved.tables[action.tableId],
                                name: action.name,
                            }
                        }
                    }
                }
            };
        case 'UPDATE_TABLE_ID':
            const projectToUpdateID = state[action.projectId];

            return {
                ...state,
                [action.projectId]: {
                    ...projectToUpdateID,
                    unsaved: {
                        ...projectToUpdateID.unsaved,
                        lastUpdatedTimeStamp: Date.now(),
                        tables: {
                            ...projectToUpdateID.unsaved.tables,
                            [action.tableId]: {
                                ...projectToUpdateID.unsaved.tables[action.tableId],
                                airtableTableId: action.airtableTableId,
                            }
                        }
                    }
                }
            };
        case 'UPDATE_TABLE_FORM_ID':
            const projectToUpdateUrl = state[action.projectId];

            return {
                ...state,
                [action.projectId]: {
                    ...projectToUpdateUrl,
                    unsaved: {
                        ...projectToUpdateUrl.unsaved,
                        lastUpdatedTimeStamp: Date.now(),
                        tables: {
                            ...projectToUpdateUrl.unsaved.tables,
                            [action.tableId]: {
                                ...projectToUpdateUrl.unsaved.tables[action.tableId],
                                formId: action.formId,
                            }
                        }
                    }
                }
            };
        case 'UPDATE_TABLE_USERS_VIEW_ID':
            const projectToUpdatViewId = state[action.projectId];

            return {
                ...state,
                [action.projectId]: {
                    ...projectToUpdatViewId,
                    unsaved: {
                        ...projectToUpdatViewId.unsaved,
                        lastUpdatedTimeStamp: Date.now(),
                        tables: {
                            ...projectToUpdatViewId.unsaved.tables,
                            [action.tableId]: {
                                ...projectToUpdatViewId.unsaved.tables[action.tableId],
                                userViewId: action.userViewId,
                            }
                        }
                    }
                }
            };
        case 'UPDATE_TABLE_PRIMARY_FIELD':
            const projectToPrimaryField = state[action.projectId];

            return {
                ...state,
                [action.projectId]: {
                    ...projectToPrimaryField,
                    unsaved: {
                        ...projectToPrimaryField.unsaved,
                        lastUpdatedTimeStamp: Date.now(),
                        tables: {
                            ...projectToPrimaryField.unsaved.tables,
                            [action.tableId]: {
                                ...projectToPrimaryField.unsaved.tables[action.tableId],
                                primaryField: action.primaryField,
                            }
                        }
                    }
                }
            };
        case 'UPDATE_TABLE_SUBTITLE_FIELD': {
            const project = state[action.projectId];

            return {
                ...state,
                [action.projectId]: {
                    ...project,
                    unsaved: {
                        ...project.unsaved,
                        lastUpdatedTimeStamp: Date.now(),
                        tables: {
                            ...project.unsaved.tables,
                            [action.tableId]: {
                                ...project.unsaved.tables[action.tableId],
                                subtitleField: action.subtitleField,
                            }
                        }
                    }
                }
            };
        }
        case 'UPDATE_TABLE_THUMBNAIL_FIELD': {
            const project = state[action.projectId];

            return {
                ...state,
                [action.projectId]: {
                    ...project,
                    unsaved: {
                        ...project.unsaved,
                        lastUpdatedTimeStamp: Date.now(),
                        tables: {
                            ...project.unsaved.tables,
                            [action.tableId]: {
                                ...project.unsaved.tables[action.tableId],
                                thumbnailField: action.thumbnailField,
                            }
                        }
                    }
                }
            };
        }
        case 'UPDATE_TABLE_REPORTING_FIELD': {
            const project = state[action.projectId];

            return {
                ...state,
                [action.projectId]: {
                    ...project,
                    unsaved: {
                        ...project.unsaved,
                        lastUpdatedTimeStamp: Date.now(),
                        tables: {
                            ...project.unsaved.tables,
                            [action.tableId]: {
                                ...project.unsaved.tables[action.tableId],
                                reportingField: action.reportingField,
                            }
                        }
                    }
                }
            };
        }
        case 'UPDATE_TABLE_SEARCH_FIELD': {
            const project = state[action.projectId];

            return {
                ...state,
                [action.projectId]: {
                    ...project,
                    unsaved: {
                        ...project.unsaved,
                        lastUpdatedTimeStamp: Date.now(),
                        tables: {
                            ...project.unsaved.tables,
                            [action.tableId]: {
                                ...project.unsaved.tables[action.tableId],
                                searchField: action.searchField,
                            }
                        }
                    }
                }
            };
        }
        case 'ADD_LAYOUT_ELEMENT':
            const projectToAddTo = state[action.projectId];

            if (action.elementName === 'View') {
                return {
                    ...state,
                    [action.projectId]: {
                        ...projectToAddTo,
                        unsaved: {
                            ...projectToAddTo.unsaved,
                            lastUpdatedTimeStamp: Date.now(),
                            tables: {
                                ...projectToAddTo.unsaved.tables,
                                [action.tableId]: {
                                    ...projectToAddTo.unsaved.tables[action.tableId],
                                    views: projectToAddTo.unsaved.tables[action.tableId].views.concat(
                                        getInitialElementFromElementName('View')
                                    ),
                                }
                            }
                        }
                    }
                };
            } else {
                return {
                    ...state,
                    [action.projectId]: {
                        ...projectToAddTo,
                        unsaved: {
                            ...projectToAddTo.unsaved,
                            lastUpdatedTimeStamp: Date.now(),
                            tables: {
                                ...projectToAddTo.unsaved.tables,
                                [action.tableId]: {
                                    ...projectToAddTo.unsaved.tables[action.tableId],
                                    layout: projectToAddTo.unsaved.tables[action.tableId].layout.concat(
                                        getInitialElementFromElementName(action.elementName)
                                    ),
                                }
                            }
                        }
                    }
                };
            }
        case 'SET_PROJECT_NAME':
            const projectToUpdateName = state[action.projectId];

            return {
                ...state,
                [action.projectId]: {
                    ...projectToUpdateName,
                    unsaved: {
                        ...projectToUpdateName.unsaved,
                        lastUpdatedTimeStamp: Date.now(),
                        name: action.name,
                    },
                },
            };
        case 'SET_PROJECT_BASE_API_KEY':
            const projectToUpdateBaseApi = state[action.projectId];

            return {
                ...state,
                [action.projectId]: {
                    ...projectToUpdateBaseApi,
                    unsaved: {
                        ...projectToUpdateBaseApi.unsaved,
                        lastUpdatedTimeStamp: Date.now(),
                        baseApiKey: action.baseApiKey,
                    }
                },
            };
        case 'SET_PROJECT_USER_API_KEY':
            const projectToUpdateUserApi = state[action.projectId];

            return {
                ...state,
                [action.projectId]: {
                    ...projectToUpdateUserApi,
                    unsaved: {
                        ...projectToUpdateUserApi.unsaved,
                        lastUpdatedTimeStamp: Date.now(),
                        userApiKey: action.userApiKey,
                    }
                },
            };
        case 'SET_PROJECT_PUSH_NOTIFICATIONS_ID': {
            const project = state[action.projectId];

            return {
                ...state,
                [action.projectId]: {
                    ...project,
                    unsaved: {
                        ...project.unsaved,
                        lastUpdatedTimeStamp: Date.now(),
                        pushNotificationsID: action.pushNotificationsID,
                    }
                },
            };
        }
        case 'SET_PROJECT_INVITE_CODE': {
            // This is a special one. It's actually saved and unsaved
            // because we've already made the network call.
            // I'm also not setting lastUpdatedTimeStamp because of the 
            // same reason.
            const project = state[action.projectId];

            return {
                ...state,
                [action.projectId]: {
                    ...project,
                    unsaved: {
                        ...project.unsaved,
                        inviteCode: action.inviteCode,
                    },
                    saved: {
                        ...project.saved,
                        inviteCode: action.inviteCode,
                    }
                },
            };
        }
        case 'SET_PROJECT':
            return {
                ...state,
                [action.project.id]: {
                    saved: action.project,
                    unsaved: action.project,
                }
            };
        case 'RESET_ALL_PROJECTS_TO_SAVED':
            const stateCopy = { ...state };

            Object.keys(stateCopy).map(key => {
                stateCopy[key].unsaved = stateCopy[key].saved;
                return null;
            });

            return {
                ...stateCopy
            };
        case 'SAVE_PROJECT':
            const projectToSave = state[action.projectId];
            return {
                ...state,
                [action.projectId]: {
                    ...projectToSave,
                    saved: projectToSave.unsaved,
                },
            };
        case 'ADD_TABLE': {
            const project = state[action.projectId];
            const id = guidGenerator();
            const newTable: AirtableTable = {
                uid: id,
                name: 'Untitled',
                airtableTableId: '',
                views: [],
                creationTimeStamp: Date.now(),
                primaryField: '',
                subtitleField: '',
                thumbnailField: '',
                layout: [],
                formId: '',
                userViewId: null,
                reportingField: null,
                searchField: null,
            };
            return {
                ...state,
                [action.projectId]: {
                    ...project,
                    unsaved: {
                        ...project.unsaved,
                        tables: {
                            ...project.unsaved.tables,
                            [id]: newTable
                        }
                    }
                },
            };
        }
        default:
            return state;
    }
};

export default reducer;