// @flow

import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { fetchAirtableFields } from './api';

import { store } from '../../../';


import type { GlobalState } from '../';
import type { Fields, FieldsState } from './types';

export const useAirtableFields = (
    airtableApiKey: ?string,
    airtableBaseId: ?string,
    airtableTableId: ?string
): Fields => {
    const fieldsState: ?FieldsState = useSelector((state: GlobalState) => airtableTableId != null ? state.airtableFields[airtableTableId] : null);
    const dispatch = useDispatch();

    useEffect(() => {
        const airtableFields = store.getState().airtableFields[airtableTableId];
        if (airtableFields == null || ((airtableFields.type === 'loaded' || airtableFields.type === 'failed') && (airtableFields.airtableApiKey !== airtableApiKey || airtableFields.airtableBaseId !== airtableBaseId))) {
            if (airtableApiKey && airtableBaseId && airtableTableId) {
                fetchAirtableFields(airtableApiKey, airtableBaseId, airtableTableId, dispatch);
            } else {
                // TODO maybe better error handling. But it actually might make sense not to
                // since airtableTableId can be incorrect because it's set by the user. So might be null.
                return;
            }
        }
    }, [airtableApiKey, airtableBaseId, airtableTableId, dispatch, fieldsState]);

    if (fieldsState != null && fieldsState.type === 'loaded') {
        return fieldsState.fields;
    } else {
        return [];
    }
};

